import "./Tables.scss";

import React from "react";
import { FaPlus, FaTimes } from "react-icons/fa";

import Button from "../../../components/Utils/Button/Button";
import ProductSelect from "../../../components/Utils/ProductSelect/ProductSelect";
import { StyledInputNumber } from "../../../components/Utils/StyledInput/StyledInput";
import useUserStore from "../../../stores/UserStore";
import ProductType, { SelectedProduct } from "../../../types/Product";

interface ProductTableProps {
  selectedProducts: SelectedProduct[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<SelectedProduct[]>>;
  products: ProductType[];
  addProductRow: () => void;
  coefficient: number;
}

// Fonction pour calculer le total et le formater en string
export const calculateTotal = (
  price?: string | null,
  quantity?: number | null,
  discount?: number | null,
  tvaRate?: number | null,
  coefficient?: number,
) => {
  // Convertir le prix en nombre et utiliser 0 si le prix est null ou indéfini
  const parsedPrice = parseFloat(price || "0");

  // Utiliser 0 pour la quantité si elle est null ou indéfini
  const parsedQuantity = quantity || 0;

  // Diviser le taux de TVA par 100 pour l'exprimer en fraction
  const parsedTvaRate = (tvaRate || 0) / 100;

  const parsedCoefficient = coefficient || 1;

  const totalHTVA = parsedPrice * parsedQuantity * parsedCoefficient;

  const totalWithDiscount = totalHTVA - (discount || 0);

  // Calculer le total
  const total = totalWithDiscount * (1 + parsedTvaRate);

  // Retourner le total formaté en chaîne avec deux décimales et ajouter " €"
  return total.toFixed(2) + " €";
};

const ProductTable: React.FC<ProductTableProps> = ({
  selectedProducts,
  setSelectedProducts,
  products,
  addProductRow,
  coefficient,
}) => {
  const { isVatSubject } = useUserStore();

  const handleQuantityChange = (value: string | null, index: number) => {
    const updatedProducts = [...selectedProducts];
    const newQuantity =
      value === null ? undefined : Math.max(0, parseInt(value, 10));
    updatedProducts[index].quantity = newQuantity;

    setSelectedProducts(updatedProducts);
  };

  const handlePriceChange = (value: string | null, index: number) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].price = value || "0";
    setSelectedProducts(updatedProducts);
  };

  const handleTvaRateChange = (value: string | null, index: number) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].tva_rate = Number(value);
    setSelectedProducts(updatedProducts);
  };

  const handleDiscountChange = (value: string | null, index: number) => {
    const updatedProducts = [...selectedProducts];
    const discount = Number(value) || 0;

    // Calculer le total HTVA sans réduction
    const price = parseFloat(updatedProducts[index].price || "0");
    const quantity = updatedProducts[index].quantity || 0;
    const totalHTVA = price * quantity * coefficient;

    // Si la réduction est supérieure au total HTVA, ne rien faire
    if (discount > totalHTVA) return;

    updatedProducts[index].discount = discount > totalHTVA ? 0 : discount;

    setSelectedProducts(updatedProducts);
  };

  const calculateSubtotal = (selectedProducts: SelectedProduct[]) => {
    let subtotalHTVA = 0;
    let subtotalTTC = 0;

    selectedProducts.forEach((currentProduct) => {
      // Assurez-vous que le prix, la quantité et la TVA ne sont pas undefined ou null, sinon utilisez 0
      const price = parseFloat(currentProduct.price || "0");
      const quantity = currentProduct.quantity || 0;
      const tvaRate = currentProduct.tva_rate || 0;
      const discount = currentProduct.discount || 0;
      const coefficientFormatted = coefficient || 1;

      // Calculer le total HTVA avec la remise appliquée
      const totalWithCoef = price * quantity * coefficientFormatted;
      const totalHTWithDiscount = totalWithCoef - discount;

      // Ajouter au sous-total HTVA
      subtotalHTVA += totalHTWithDiscount;

      // Calculer le total TTC
      const totalTTC = totalHTWithDiscount * (1 + tvaRate / 100);

      // Ajouter au sous-total TTC
      subtotalTTC += totalTTC;
    });

    // Retourner les résultats formatés avec deux décimales
    return {
      subtotalHTVA: subtotalHTVA.toFixed(2),
      subtotalTTC: subtotalTTC.toFixed(2),
    };
  };

  const deleteProductRow = (rowId: number | undefined) => {
    if (!rowId) return;
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((product) => product.row_id !== rowId),
    );
  };

  return (
    <div className="tableResponsive">
      <table className="table">
        <thead>
          <tr>
            <th>Product</th>
            <th>Quantité</th>
            <th>Prix / unité</th>
            <th>Réduc</th>
            {isVatSubject && <th>TVA</th>}
            {isVatSubject && <th>TOTAL HTVA</th>}
            <th>TOTAL TTC</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectedProducts.map((product, index) => (
            <tr
              key={product.product_id + (selectedProducts[index].row_id ?? 0)}
            >
              <td>
                <ProductSelect
                  setValue={(newProduct) => {
                    const updatedProducts = [...selectedProducts];
                    const index = updatedProducts.findIndex(
                      (prod) => prod.row_id === product.row_id,
                    );
                    updatedProducts[index] = {
                      ...newProduct,
                      quantity: product.quantity,
                      row_id: product.row_id,
                    };
                    setSelectedProducts(updatedProducts);
                  }}
                  value={product}
                  productList={products}
                  selectedProducts={selectedProducts}
                  placeholder="Sélectionnez un produit"
                />
              </td>
              <td>
                <StyledInputNumber
                  label=""
                  value={product.quantity?.toString() || null}
                  setValue={(value) => handleQuantityChange(value, index)}
                  min={"0"}
                  max={product.stock ? product.stock.toString() : "9999999"}
                  className="quantityInput"
                />
              </td>
              <td>
                <StyledInputNumber
                  label=""
                  value={product.price || null}
                  setValue={(value) => handlePriceChange(value, index)}
                  min={"0"}
                  className="priceInput"
                />
              </td>
              <td>
                <StyledInputNumber
                  label=""
                  value={product.discount ? product.discount.toString() : "0"}
                  setValue={(value) => handleDiscountChange(value, index)}
                  max={(
                    Number(product.price) *
                    Number(product.quantity) *
                    coefficient
                  ).toString()}
                  className="discountInput"
                />
              </td>
              {isVatSubject && (
                <td>
                  <StyledInputNumber
                    label=""
                    value={product.tva_rate ? product.tva_rate.toString() : "0"}
                    setValue={(value) => handleTvaRateChange(value, index)}
                    max={"100"}
                    min={"0"}
                    className="vatInput"
                  />
                </td>
              )}
              {isVatSubject && (
                <td>
                  {calculateTotal(
                    product.price,
                    product.quantity,
                    product.discount,
                    null,
                    coefficient,
                  )}
                </td>
              )}
              <td>
                {calculateTotal(
                  product.price,
                  product.quantity,
                  product.discount,
                  product.tva_rate,
                  coefficient,
                )}
              </td>
              <td>
                <Button
                  onClick={() => deleteProductRow(product.row_id)}
                  icon={<FaTimes color="red" />}
                  className="squareButton"
                  style="square"
                ></Button>
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            {isVatSubject && (
              <>
                <td></td>
                <td></td>
              </>
            )}
            <td>
              <Button
                onClick={() => addProductRow()}
                icon={<FaPlus />}
                className="squareButton"
                style="square"
              ></Button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>SOUS-TOTAL</td>
            <td></td>
            <td></td>
            <td></td>
            {isVatSubject && (
              <>
                <td></td>
                <td>{calculateSubtotal(selectedProducts).subtotalHTVA}</td>
              </>
            )}
            <td>{calculateSubtotal(selectedProducts).subtotalTTC} €</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ProductTable;
