import "./AccountingList.scss";

import React, { useState } from "react";

import Button from "../../../components/Utils/Button/Button";
import ButtonSwitcher from "../../../components/Utils/ButtonSwitcher/ButtonSwitcher";
import Calendar from "../../../components/Utils/Calendar/Calendar";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import useAccountingStore from "../../../stores/AccountingStore";
import useLoadingStore from "../../../stores/LoadingStore";
import AccountingExpenseType from "../../../types/AccountingExpense";
import AccountingRevenueType from "../../../types/AccountingRevenue";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import ExpenseList from "./Expense/ExpenseList";
import RevenueList from "./Revenue/RevenueList";

const AccountingList: React.FC<{
  onEdit: (expense: AccountingExpenseType | AccountingRevenueType) => void;
  onEditPage?: boolean;
}> = ({ onEdit, onEditPage }) => {
  const [search, setSearch] = useState("");
  const { accountingType, setAccountingType } = useAccountingStore();
  const { loadingStates, setLoading } = useLoadingStore();
  const startOfYear = new Date();
  startOfYear.setMonth(0, 1);

  const endOfMonth = new Date();
  endOfMonth.setMonth(endOfMonth.getMonth() + 1, 0);

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfYear,
    endOfMonth,
  ]);
  const [startDate, endDate] = dateRange;

  const handleExport = async () => {
    setLoading(`exportAccounting`, true);

    const formatDateForFileName = (date: Date) => {
      return date.toISOString().split("T")[0];
    };

    try {
      const expensesData = await appFetch("/api/accounting/exportAccounting", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        }),
      });

      if (expensesData) {
        const formattedStartDate = formatDateForFileName(startDate);
        const formattedEndDate = formatDateForFileName(endDate);
        const fileName = `accounting_${formattedStartDate}_to_${formattedEndDate}.zip`;

        const url = window.URL.createObjectURL(expensesData);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }

      setLoading(`exportAccounting`, false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(`exportAccounting`, false);
    }
  };

  return (
    <div className={`accountingListPage ${onEditPage ? "onEditPage" : ""}`}>
      <div className="accountingListPageTitle">
        <div className="left">
          <StyledInputText
            label="Rechercher"
            value={search}
            setValue={setSearch}
            className="searchInput"
          />
          <Calendar
            startDate={startDate}
            endDate={endDate}
            setDateRange={setDateRange}
          />
        </div>
        <div className="right">
          <Button
            className="downloadButton"
            onClick={handleExport}
            label="Exporter"
            isLoading={loadingStates[`exportAccounting`]}
          />
          <ButtonSwitcher
            datas={[
              { id: "sortie", name: "Sortie" },
              { id: "rentrée", name: "Rentrée" },
            ]}
            setValue={setAccountingType}
            value={accountingType}
            className="accountingFormButtonSwitch"
          />
        </div>
      </div>

      {accountingType.id === "sortie" && (
        <ExpenseList
          onEdit={onEdit}
          onEditPage={onEditPage}
          search={search}
          endDate={endDate}
          startDate={startDate}
        />
      )}
      {accountingType.id === "rentrée" && (
        <RevenueList
          onEdit={onEdit}
          onEditPage={onEditPage}
          search={search}
          endDate={endDate}
          startDate={startDate}
        />
      )}
    </div>
  );
};

export default AccountingList;
