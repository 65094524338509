import "./AddOrganizationUser.scss";

import React, { useState } from "react";
import toast from "react-hot-toast";

import Button from "../../../components/Utils/Button/Button";
import { StyledInputEmail } from "../../../components/Utils/StyledInput/StyledInput";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const AddOrganizationUser: React.FC = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await appFetch("/api/organization/addOrganizationUser", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      if (response) {
        // redirect to home page
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="addOrganizationUser">
      <form onSubmit={handleSubmit}>
        <StyledInputEmail
          label="Email de l'utilisateur"
          value={email}
          setValue={setEmail}
          required={true}
          className="inputLogin"
        />
        <Button
          type="submit"
          className="buttonLogin"
          label="Ajouter à l'organisation"
        />
      </form>
    </div>
  );
};

export default AddOrganizationUser;
