import "./Dashboard.scss";
import "react-datepicker/dist/react-datepicker.css";

import EventRevenues from "./EventRevenues/EventRevenues";
import ExpensesVsRevenuesChart from "./ExpensesVsRevenuesChart/ExpensesVsRevenuesChart";

const Dashboard = () => {
  return (
    <div className={`dashboardPage`}>
      <div className="dashboardPageDiv">
        <ExpensesVsRevenuesChart />
        <EventRevenues />
      </div>
    </div>
  );
};

export default Dashboard;
