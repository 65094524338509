import "./ProductSelect.scss";

import React, { useEffect, useRef, useState } from "react";

import { ChevronDown, GrClose } from "../../../assets/svg/svgIcons";
import useWindowWidth from "../../../hooks/useWindowWidth";
import useDropdownStore from "../../../stores/DropdownStore";
import ProductType, { SelectedProduct } from "../../../types/Product";
import { StyledInputText } from "../StyledInput/StyledInput";

interface ProductSelectProps {
  value: SelectedProduct;
  setValue: (value: SelectedProduct) => void;
  productList: ProductType[];
  disabled?: boolean;
  className?: string;
  addProductRow?: (product: ProductType) => void;
  selectedProducts: SelectedProduct[];
  placeholder?: string;
}

const ProductSelect: React.FC<ProductSelectProps> = ({
  value,
  setValue,
  productList,
  disabled,
  className,
  addProductRow,
  selectedProducts,
  placeholder,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { openDropdownId, setOpenDropdownId } = useDropdownStore();
  const { isMobile } = useWindowWidth();

  const isComponentVisible =
    openDropdownId === `product-${value.product_id}-${value.row_id}`;

  const handleClickItem = () => {
    if (isComponentVisible) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(`product-${value.product_id}-${value.row_id}`);
    }
  };
  // when click on toggle switch
  const setProduct = (product_id: number) => {
    const newProduct = productList.find((c) => c.product_id === product_id);
    if (newProduct) {
      setValue({ ...newProduct, quantity: 1 });
      addProductRow && addProductRow(newProduct);
    }
    setOpenDropdownId(null);
  };

  // when click outside dropdown
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      target &&
      typeof target.className === "string" &&
      ref.current &&
      !ref.current.contains(target) &&
      target.className !== "selectMenuName" &&
      !target.className.includes("productInput") &&
      !target.className.includes("selectName") &&
      !target.className.includes("placeholderName") &&
      !target.className.includes("productName")
    ) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    // when click outside dropdown, close dropdown
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const filteredProducts = productList.filter(
    (product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !selectedProducts.some(
        (selected) => selected.product_id === product.product_id,
      ),
  );

  return (
    <div
      className={`productSelectDiv ${
        disabled ? "productSelectDivDisabled" : ""
      } ${className}`}
    >
      <div className="selectElement">
        <div
          className={`selectMenu ${
            isComponentVisible === true ? "active" : ""
          }`}
        >
          <div className="selectMenuName" onClick={() => handleClickItem()}>
            <div className="selectName">
              {value.name !== "" ? (
                <span className="productName">{value.name}</span>
              ) : (
                <span className="placeholderName">{placeholder}</span>
              )}
            </div>
            {!isMobile && <ChevronDown className="arrowDown" />}
          </div>
          <div className="submenu" ref={ref}>
            <div className="subselectItemTitle">
              <span>Choose product</span>
              <GrClose
                className="grClose"
                onClick={() => setOpenDropdownId(null)}
              />
            </div>
            <div className="submenuItemsContainer">
              <StyledInputText
                value={searchTerm}
                label=""
                className="productInput"
                setValue={(e: string) => setSearchTerm(e)}
                placeholder="Search..."
              />
              {filteredProducts.map((item) => (
                <div
                  className="subselectItem"
                  key={item.product_id}
                  onClick={() => setProduct(item.product_id)}
                >
                  <span className="">{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSelect;
