import "./PrivacyPolicy.scss";

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Button from "../../components/Utils/Button/Button";

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="loginRoot">
      <div className="privacy">
        <Button
          icon={<FaArrowLeft />}
          onClick={() => navigate("/")}
          style="square"
        />
        <h1 className="title">Privacy Policy</h1>
        <p className="lastUpdated">Effective date: 2024-03-05</p>
        <p className="lastUpdated">Updated on: 2024-03-05</p>
        <div className="chapter">
          <p className="chapterText">
            This Privacy Policy explains the policies of Falc&apos;Ohm System
            ASBL on the collection and use of the information we collect when
            you access https://nestohm.com (the “Service”). This Privacy Policy
            describes your privacy rights and how you are protected under
            privacy laws
          </p>
          <p className="chapterText">
            By using our Service, you are consenting to the collection and use
            of your information in accordance with this Privacy Policy. Please
            do not access or use our Service if you do not consent to the
            collection and use of your information as outlined in this Privacy
            Policy. This Privacy Policy has been created with the help of{" "}
            <a
              target="_blank"
              href="https://cookie-script.com/privacy-policy-generator"
              rel="noreferrer"
            >
              CookieScript Privacy Policy Generator
            </a>
            .
          </p>
          <p className="chapterText">
            Falc&apos;Ohm System ASBL is authorized to modify this Privacy
            Policy at any time. This may occur without prior notice.
          </p>

          <p className="chapterText">
            Falc&apos;Ohm System ASBL will post the revised Privacy Policy on
            the https://nestohm.com website
          </p>
        </div>
        <h3 className="title2">
          Collection and Use of Your Personal Information
        </h3>
        <div className="chapter">
          <h4 className="chapterTitle">Information We Collect</h4>
          <p className="chapterText">
            When using our Service, you will be prompted to provide us with
            personal information used to contact or identify you.
            https://nestohm.com collects the following information:
          </p>

          <ul>
            <li>Usage Data</li>
            <li>Name</li>
            <li>Email</li>
            <li>Mobile Number</li>
          </ul>

          <p className="chapterText">Usage Data includes the following:</p>
          <ul>
            <li>
              Internet Protocol (IP) address of computers accessing the site
            </li>
            <li>Web page requests</li>
            <li>Referring web pages</li>
            <li>Browser used to access site</li>
            <li>Time and date of access</li>
          </ul>
        </div>
        <div className="chapter">
          <h4 className="chapterTitle">How We Collect Information</h4>
          <p className="chapterText">
            https://nestohm.com collects and receives information from you in
            the following manner:
          </p>
          <ul>
            <li>
              When you fill a registration form or otherwise submit your
              personal information.
            </li>
            <li>When you interact with our Service.</li>
          </ul>
          <p className="chapterText">
            Your information will be stored for up to 365 days after it is no
            longer required to provide you the services. Your information may be
            retained for longer periods for reporting or record- keeping in
            accordance with applicable laws. Information which does not identify
            you personally may be stored indefinitely.
          </p>
        </div>
        <div className="chapter">
          <h4 className="chapterTitle">How We Use Your Information</h4>
          <p className="chapterText">
            https://nestohm.com may use your information for the following
            purposes:
          </p>
          <ul>
            <li>
              <b>Providing and maintaining our Service,</b> as well as
              monitoring the usage of our Service.
            </li>
            <li>
              <b>For other purposes.</b> Falc&apos;Ohm System ASBL will use your
              information for data analysis to identify usage trends or
              determine the effective of our marketing campaigns when
              reasonable. We will use your information to evaluate and improve
              our Service, products, services, and marketing efforts.
            </li>
            <li>
              <b>Testimonials and customer feedback collection.</b> If you share
              a testimonial or review about your experience using our Service,
              it will be shared or otherwise used on the website.
            </li>
            <li>
              <b>To update you</b> with news, general information, special
              offers, new services, and events.
            </li>
            <li>
              <b>Dispute resolution and site protection.</b> Your information
              will be used in the instance of a legal dispute to resolve issues
              related to our Service.
            </li>
          </ul>
        </div>
        <div className="chapter">
          <h4 className="chapterTitle">How We Share Your Information</h4>
          <p className="chapterText">
            Falc&apos;Ohm System ASBL will share your information, when
            applicable, in the following situations:
          </p>
          <ul>
            <li>
              <b>With your consent.</b> Falc&apos;Ohm System ASBL will share
              your information for any purpose with your explicit consent.
            </li>
          </ul>
        </div>
        <div className="chapter">
          <h4 className="chapterTitle">Third-party Sharing</h4>

          <p className="chapterText">
            Your information may be disclosed for additional reasons, including:
          </p>
          <ul>
            <li>
              Complying with applicable laws, regulations, or court orders.
            </li>
            <li>
              Responding to claims that your use of our Service violates
              third-party rights.
            </li>
            <li>
              Enforcing agreements you make with us, including this Privacy
              Policy.
            </li>
          </ul>
        </div>
        <div className="chapter">
          <h4 className="chapterTitle">Cookies</h4>
          <p className="chapterText">
            Cookies are small text files that are placed on your computer by
            websites that you visit. Websites use cookies to help users navigate
            efficiently and perform certain functions. Cookies that are required
            for the website to operate properly are allowed to be set without
            your permission. All other cookies need to be approved before they
            can be set in the browser.
          </p>
          <ul>
            <li>
              <b>Strictly necessary cookies.</b> Strictly necessary cookies
              allow core website functionality such as user login and account
              management. The website cannot be used properly without strictly
              necessary cookies.
            </li>
          </ul>
        </div>
        <div className="chapter">
          <h4 className="chapterTitle">Security</h4>
          <p className="chapterText">
            Your information’s security is important to us. https://nestohm.com
            utilizes a range of security measures to prevent the misuse, loss,
            or alteration of the information you have given us. However, because
            we cannot guarantee the security of the information you provide us,
            you must access our service at your own risk.
          </p>
          <p className="chapterText">
            Falc&apos;Ohm System ASBL is not responsible for the performance of
            websites operated by third parties or your interactions with them.
            When you leave this website, we recommend you review the privacy
            practices of other websites you interact with and determine the
            adequacy of those practices.
          </p>
        </div>
        <div className="chapter">
          <h4 className="chapterTitle">Contact Us</h4>
          <p className="chapterText">
            For any questions, please contact us through the following methods:
          </p>
          <p className="chapterText">Name: Falc&apos;Ohm System ASBL</p>
          <p className="chapterText">
            Address: Rue des croix du feu 4, 1473 Glabais Belgium
          </p>
          <p className="chapterText">Email: falcohm6tm@outlook.com</p>
          <p className="chapterText">Website: https://nestohm.com</p>
        </div>
        B
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
