// AccountingStore.tsx
import { create } from "zustand";

import { ButtonSwitcherValue } from "../components/Utils/ButtonSwitcher/ButtonSwitcher";
import AccountingExpenseType from "../types/AccountingExpense";
import AccountingRevenueType from "../types/AccountingRevenue";

interface AccountingState {
  accountingType: ButtonSwitcherValue;
  setAccountingType: (accountingType: ButtonSwitcherValue) => void;
  expense: AccountingExpenseType;
  setExpense: (
    expense:
      | AccountingExpenseType
      | ((prevExpense: AccountingExpenseType) => AccountingExpenseType),
  ) => void;

  revenue: AccountingRevenueType;
  setRevenue: (
    revenue:
      | AccountingRevenueType
      | ((prevRevenue: AccountingRevenueType) => AccountingRevenueType),
  ) => void;
}

const useAccountingStore = create<AccountingState>((set) => ({
  accountingType: { id: "sortie", name: "Sortie" },
  setAccountingType: (accountingType) => set(() => ({ accountingType })),
  // État initial de l'expense
  expense: {
    expense_id: -1,
    user_id: -1,
    product_name: "",
    price: 0,
    recorded_date: new Date(),
    payment_method: "",
    file_path: "",
    file: null,
    user: { name: "Organization", user_id: -1, email: "" },
  },
  setExpense: (expense) =>
    set((state) => ({
      expense: typeof expense === "function" ? expense(state.expense) : expense,
    })),

  // État initial du revenue
  revenue: {
    revenue_id: -1,
    product_name: "",
    unit_price: 0,
    amount: 0,
    recorded_date: new Date(),
    payment_method: "",
    file_path: "",
    file: null,
  },
  setRevenue: (revenue) =>
    set((state) => ({
      revenue: typeof revenue === "function" ? revenue(state.revenue) : revenue,
    })),
}));

export default useAccountingStore;
