import { useGoogleLogin } from "@react-oauth/google";
import toast from "react-hot-toast";
import { FaGoogle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/Utils/Button/Button";
import { ButtonSwitcherValue } from "../../../components/Utils/ButtonSwitcher/ButtonSwitcher";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

interface GmailButtonProps {
  loginType: ButtonSwitcherValue;
}
const GmailButton = ({ loginType }: GmailButtonProps) => {
  const navigate = useNavigate();

  const onRegister = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      const { code } = codeResponse;

      if (code) {
        const response = await appFetch(
          "/api/auth/google",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ code }),
          },
          navigate,
        );

        if (response && !response.error) {
          navigate("/quote");
        }
        if (response.error === 401) {
          navigate("/organizationSetup");
        }
      }
    },
    onError: (error) => {
      console.log(error);
      toast.error("Error while signing in");
    },
  });

  return (
    <Button
      label={loginType.name + " avec Google"}
      onClick={() => onRegister()}
      className="gmailButton"
      icon={<FaGoogle />}
    />
  );
};

export default GmailButton;
