// HeaderHero.tsx
import "./HeaderHero.scss";

import React from "react";
import { useNavigate } from "react-router-dom";

import hero from "../../../assets/img/landingPage/hero.png";
import Button from "../../../components/Utils/Button/Button";
interface HeaderHeroProps {
  slogan: string;
  subTitle: string;
  actionButtonText: string;
}

const HeaderHero: React.FC<HeaderHeroProps> = ({
  slogan,
  subTitle,
  actionButtonText,
}) => {
  const navigate = useNavigate();
  return (
    <header className="headerHero">
      <div className="content">
        <h1>{slogan}</h1>
        <p>{subTitle}</p>
        <Button
          onClick={() => {
            navigate("/login");
          }}
          label={actionButtonText}
          width={"auto"}
          height={"60px"}
          style="inverted"
        />
      </div>
      <img src={hero} alt="Hero" className="heroImg" />
    </header>
  );
};

export default HeaderHero;
