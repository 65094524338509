import "./JoinOrganization.scss";

import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/Utils/Button/Button";
import { StyledInputEmail } from "../../../components/Utils/StyledInput/StyledInput";
import OrganizationType from "../../../types/Organization";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const JoinOrganization: React.FC = () => {
  const [organisation, setOrganisation] = useState<OrganizationType>({
    organization_id: 0,
    organization_name: "",
    address: "",
    phone: "",
    email: "",
    vat_number: undefined,
    bank_account: "",
  });
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await appFetch("/api/organization/requests/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(organisation),
      });

      if (response) {
        // redirect to home page
        navigate("/login");
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="joinOrganization">
      <form onSubmit={handleSubmit}>
        <StyledInputEmail
          label="Email de l'organisation*"
          value={organisation.email ?? ""}
          setValue={(value: string) =>
            setOrganisation({ ...organisation, email: value })
          }
          required={true}
          className="inputLogin"
        />
        <Button
          type="submit"
          className="buttonLogin"
          label="Demander à rejoindre"
        />
      </form>
    </div>
  );
};
export default JoinOrganization;
