import "./ModalContent.scss";

import { FiTrash2 } from "react-icons/fi";

import Button from "../../../../../components/Utils/Button/Button";
import {
  StyledInputNumber,
  StyledInputText,
  StyledTextArea,
} from "../../../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../../../stores/LoadingStore";
import ServiceType from "../../../../../types/Service";

interface ModalContentProps {
  setUpdatedService: React.Dispatch<React.SetStateAction<ServiceType>>;
  updatedService: ServiceType;
}
const ModalContent = ({
  updatedService,
  setUpdatedService,
}: ModalContentProps) => {
  const handleChange =
    (key: keyof ServiceType) => (value: string | number | File | null) => {
      setUpdatedService((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    };
  return (
    <div className="modalContent">
      <StyledInputText
        label="Nom*"
        value={updatedService.name}
        setValue={handleChange("name")}
      />
      <StyledTextArea
        label="Description*"
        value={updatedService.description}
        setValue={handleChange("description")}
      />
      <StyledInputNumber
        label="Prix*"
        value={updatedService.price}
        setValue={handleChange("price")}
      />
    </div>
  );
};

interface ModalFooterButtonProps {
  onClick?: () => void;
  onClickWithParam?: (param: number) => void;
  paramValue?: number;
  addService?: boolean;
  onDelete?: () => void;
}

const ModalFooterButton: React.FC<ModalFooterButtonProps> = ({
  onClick,
  onClickWithParam,
  paramValue,
  addService,
  onDelete,
}) => {
  const { loadingStates } = useLoadingStore();

  return (
    <>
      {onClick && (
        <Button
          className="modalFooterButton"
          onClick={onClick}
          label={addService ? "Ajouter" : "Modifier"}
          isLoading={loadingStates.addService}
        />
      )}
      {onClickWithParam && paramValue !== undefined && (
        <Button
          className="modalFooterButton"
          onClick={() => onClickWithParam(paramValue)}
          label={addService ? "Ajouter" : "Modifier"}
          isLoading={loadingStates.updateService}
        />
      )}
      {!addService && (
        <Button
          className="modalFooterButton"
          onClick={onDelete}
          icon={<FiTrash2 />}
          isLoading={loadingStates.deleteService}
        />
      )}
    </>
  );
};

export default ModalContent;
export { ModalFooterButton };
