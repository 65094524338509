import "./LandingPage.scss";

import { useEffect } from "react";

import CatalogueVideo from "../../assets/videos/catalogue.mp4";
import CreateClientVideo from "../../assets/videos/create-client.mp4";
import DevisVideo from "../../assets/videos/devis.mp4";
import FactureVideo from "../../assets/videos/facture.mp4";
import Footer from "../../components/Footer/Footer";
import useVerifyToken from "../../hooks/useVerifyToken";
import Benefits from "./Benefits/Benefits";
import ContentPanel from "./ContentPanel/ContentPanel";
import FAQ from "./FAQ/FAQ";
import FeaturesPresentation from "./FeaturesPresentation/FeaturesPresentation";
import Header from "./Header/Header";
import HeaderHero from "./HeaderHero/HeaderHero";
import Plan from "./Plan/Plan";

const LandingPage = () => {
  const { verifyToken } = useVerifyToken();

  useEffect(() => {
    verifyToken();
  }, []);

  const videoList = [
    {
      title: "Créer un client",
      description:
        "Gèrez vos clients avec une interface simple. Ajoutez ses informations et simplifiez la gestion de votre réseau.",
      videoUrl: CreateClientVideo,
      progress: 0,
    },
    {
      title: "Catalogue",
      description:
        "Gèrez votre catalogue avec une gestion efficace des produits et services. Créez des catégories, ajoutez des produits et des services, et gérez facilement vos stocks.",
      videoUrl: CatalogueVideo,
      progress: 0,
    },
    {
      title: "Devis",
      description:
        "Créez des devis, ajoutez des produits et des services, et téléchargez vos directement le PDF.",
      videoUrl: DevisVideo,
      progress: 0,
    },
    {
      title: "Factures",
      description:
        "Facturez vos devis avec une interface simple et rapide. Gérez facilement vos factures et vos paiements.",
      videoUrl: FactureVideo,
      progress: 0,
    },
  ];

  return (
    <div className="landingPage">
      <Header />
      <HeaderHero
        slogan="Gérez votre entreprise avec facilité"
        subTitle="Simplifiez votre gestion d'entreprise avec une suite complète : gestion des clients, catalogues produits, devis et facturations simplifiés, collaboration multi-utilisateur et bien plus. Tout ce dont vous avez besoin, en un seul endroit, pour accélérer votre productivité et dynamiser votre croissance. Découvrez comment avec un essai gratuit."
        actionButtonText="Réclamez votre essai gratuit"
      />
      <ContentPanel videos={videoList} />
      <Benefits />
      <FeaturesPresentation />
      <Plan />
      <FAQ />
      <Footer />
    </div>
  );
};

export default LandingPage;
