import "./Clients.scss";

import ClientList from "./ClientList/ClientList";

const Clients = () => {
  return (
    <div className="root">
      <div className="clientsPage">
        <ClientList />
      </div>
    </div>
  );
};

export default Clients;
