/// <reference types="vite-plugin-svgr/client" />
import "./App.scss";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Accounting from "./pages/Accounting/Accounting";
import Catalogue from "./pages/Catalogue/Catalogue";
import Clients from "./pages/Clients/Clients";
import Events from "./pages/Events/Events";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Quote from "./pages/Home/Quote";
import Invoices from "./pages/Invoices/Invoices";
import LandingPage from "./pages/LandingPage/LandingPage";
import Login from "./pages/Login/Login";
import Organization from "./pages/Organization/Organization";
import OrganizationSetup from "./pages/OrganizationSetup/OrganizationSetup";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Sale from "./pages/Sale/Sale";
import StripePayment from "./pages/StripePayment/StripePayment";
import TermsOfUses from "./pages/TermsOfUses/TermsOfUses";
import useUserStore from "./stores/UserStore";
import appFetch from "./Utils/Services/Fetch/appFetch";
import PrivateRoutes from "./Utils/Services/PrivateRoutes/PrivateRoutes";

function App() {
  const stripePromise = loadStripe(
    import.meta.env.DEV
      ? "pk_test_51ONdUaG5154ZKJmxSWwkv2liFl6mX0I1qIfzQj0VJTammCrWMrEY7hE7mTbmjKv8zRR4A7C89zUAYxiWcQqFtTxF00mxQfmvjV"
      : "pk_live_51ONdUaG5154ZKJmxzPOVXfqPCgTUOUzBZjE25HO1wV5PYk24c2QahD0Xn0hYTsIhK6MUsm1VaPtHULRXOJZgq7qp009XQJ8I0b",
  );

  const { isAuthenticated, setIsVatSubject } = useUserStore();

  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        const orgData = await appFetch(`/api/organization/getOrganization`, {
          method: "GET",
        });
        if (orgData && orgData.organization) {
          setIsVatSubject(orgData.organization.is_vat_subject);
        }
      };
      fetchData();
    }
  }, [isAuthenticated]);

  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <Router>
          {isAuthenticated && (
            <header>
              <Navbar />
            </header>
          )}
          <GoogleOAuthProvider clientId="611121696575-svcd89o61cnarnitci9hsb2d2t2v7np5.apps.googleusercontent.com">
            <Routes>
              <Route path="/" element={<LandingPage />} />

              <Route element={<PrivateRoutes />}>
                <Route path="/quote" element={<Quote />} />

                <Route path="/accounting" element={<Accounting />} />
                <Route path="/catalogue" element={<Catalogue />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/events" element={<Events />} />
                <Route path="/organization" element={<Organization />} />
                <Route path="/sales" element={<Sale />} />
              </Route>
              <Route
                path="/organizationSetup"
                element={<OrganizationSetup />}
              />
              <Route path="/payment/:mode" element={<StripePayment />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/resetPassword/:token" element={<ResetPassword />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-uses" element={<TermsOfUses />} />

              {/* redirige les routes non trouvé vers / */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </GoogleOAuthProvider>
        </Router>
      </Elements>

      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
}

export default App;
