import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Button from "../../components/Utils/Button/Button";
import { StyledInputPassword } from "../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../stores/LoadingStore";
import appFetch from "../../Utils/Services/Fetch/appFetch";

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { loadingStates, setLoading } = useLoadingStore();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const { token } = useParams();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas");
      return;
    }

    try {
      setLoading("ResetPassword", true);
      const response = await appFetch(
        "/api/auth/resetPassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password, token }),
        },
        navigate,
      );

      if (response) {
        navigate("/");
        toast.success(response.message);
      }
      setLoading("ResetPassword", false);
    } catch (error) {
      console.error("Error:", error);
      setLoading("ResetPassword", false);
    }
  };

  return (
    <div className="loginRoot">
      <div className="loginPage">
        <Button
          icon={<FaArrowLeft />}
          onClick={() => navigate("/")}
          style="square"
        />
        <form onSubmit={handleSubmit}>
          <StyledInputPassword
            label="Nouveau mot de passe"
            value={password}
            setValue={setPassword}
            required={true}
            className="inputLogin"
          />
          <StyledInputPassword
            label="Confirmer le mot de passe"
            value={confirmPassword}
            setValue={setConfirmPassword}
            required={true}
            className="inputLogin"
          />

          <Button
            type="submit"
            label={"Modifier le mot de passe"}
            className="loginSubmitButton"
            isLoading={loadingStates.ResetPassword}
          />
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
