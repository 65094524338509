import "./Events.scss";

import EventsList from "./EventsList/EventsList";

const Events = () => {
  return (
    <div className="root">
      <div className="eventsPage">
        <EventsList />
      </div>
    </div>
  );
};

export default Events;
