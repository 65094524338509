import "./HeaderButtons.scss";

import React, { ReactNode } from "react";

type HeaderButtonsType = { id: string; render: ReactNode };

interface HeaderButtonsProps {
  activeButton: string;
  setActiveButton?: (button: string) => void;
  buttons: HeaderButtonsType[];
  showHelpMeWith?: boolean;
}

const HeaderButtons: React.FC<HeaderButtonsProps> = ({
  activeButton,
  setActiveButton,
  buttons,
}) => {
  const activeIndex = buttons.findIndex((button) => button.id === activeButton);
  return (
    <div className="mainContainerHeaders">
      <div className="headerButtonsWrapper">
        {buttons.map((button, index) => {
          const isActive = button.id === activeButton;
          let className = "headerButtons";
          if (isActive) {
            className += " activeButton";
          }
          if (isActive) {
            className += " activeButton";
          } else if (index === activeIndex - 1) {
            className += " nextToLeftActive";
          }

          return (
            <button
              key={button.id}
              className={className}
              onClick={() => setActiveButton && setActiveButton(button.id)}
            >
              <div className="buttonContent">{button.render}</div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default HeaderButtons;
