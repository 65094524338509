import "./OrganizationRequests.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiCheck, FiTrash2 } from "react-icons/fi";

import Button from "../../../components/Utils/Button/Button";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import OrganizationRequestType from "../../../types/OrganizationRequest";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const OrganizationRequests = () => {
  const [demandes, setDemandes] = useState<OrganizationRequestType[]>([]);
  const [error, setError] = useState<string>("");
  const [search, setSearch] = useState("");

  const filteredRequests = demandes?.filter((demande) => {
    return (
      demande.user?.name?.toLowerCase().includes(search.toLowerCase()) ||
      demande.status.toString().includes(search.toLowerCase())
    );
  });

  const fetchData = async () => {
    const data = await appFetch(`/api/organization/requests/list`, {
      method: "GET",
    });
    if (data) {
      if (data.message === "Pas de demande en attente") {
        setError(data.message);
        return;
      }
      setDemandes(data.requests);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rejectsRequest = async (requestId: number) => {
    const response = await appFetch(
      `/api/organization/requests/delete?request_id=${requestId}`,
      {
        method: "DELETE",
      },
    );
    if (response) {
      toast.success(response.message);
      setDemandes(
        demandes.filter((demande) => demande.request_id !== requestId),
      );
    }
  };

  const validateRequest = async (requestId: number) => {
    const response = await appFetch(
      `/api/organization/requests/validate?request_id=${requestId}`,
      {
        method: "PUT",
      },
    );
    if (response && response.error) {
      toast.error(response.message);
    } else {
      toast.success("Demande validée");
      setDemandes(
        demandes.filter((demande) => demande.request_id !== requestId),
      );
    }
  };

  return (
    <div className="demandesListPage">
      <div className="demandesList">
        <StyledInputText
          label="Rechercher"
          value={search}
          setValue={setSearch}
          className="searchInput"
        />
        <table className="table">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Email</th>
              <th>Status</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredRequests.map((request) => {
              return (
                <tr key={request.request_id}>
                  <td>{request.user?.name}</td>

                  <td>{request.user?.email}</td>
                  <td>{request.status}</td>

                  <td>{new Date(request.created_at).toLocaleDateString()}</td>

                  <td>
                    <Button
                      className="validateButton"
                      onClick={() => validateRequest(request.request_id)}
                      icon={<FiCheck />}
                    />
                    <Button
                      className="deleteButton"
                      onClick={() => rejectsRequest(request.request_id)}
                      icon={<FiTrash2 />}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {error && <div className="demandesItem">{error}</div>}
      </div>
    </div>
  );
};

export default OrganizationRequests;
