import "./Header.scss";

import { useNavigate } from "react-router-dom";

import { Logo } from "../../../assets/svg/svgIcons";
import Button from "../../../components/Utils/Button/Button";
import useUserStore from "../../../stores/UserStore";

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserStore();

  return (
    <header className="landingHeader">
      <Logo />
      <Button
        label="Se connecter"
        onClick={() => {
          navigate(isAuthenticated ? "/quote" : "/login");
        }}
        width={"auto"}
        style="inverted"
      />
    </header>
  );
};

export default Header;
