import "./Accounting.scss";

import React, { useEffect, useState } from "react";

import HeaderButtons from "../../components/Utils/HeaderButtons/HeaderButtons";
import useAccountingStore from "../../stores/AccountingStore";
import AccountingExpenseType from "../../types/AccountingExpense";
import AccountingRevenueType from "../../types/AccountingRevenue";
import AccountingForm from "./AccountingForm/AccountingForm";
import AccountingList from "./AccountingList/AccountingList";
import Dashboard from "./Dashboard/Dashboard";

const Accounting: React.FC = () => {
  const [activeButton, setActiveButton] = useState("Ajouter");
  const [expenseToEdit, setExpenseToEdit] = useState<
    AccountingExpenseType | AccountingRevenueType | null
  >(null);

  const { accountingType } = useAccountingStore();

  const handleEditAccounting = (
    quote: AccountingExpenseType | AccountingRevenueType,
  ) => {
    setExpenseToEdit(quote);
    setActiveButton("Modifier");
  };

  const buttons = [
    { id: "Ajouter", render: "Ajouter" },
    { id: "Modifier", render: "Modifier" },
    { id: "Liste", render: "Liste" },
    { id: "Dashboard", render: "Dashboard" },
  ];

  useEffect(() => {
    setExpenseToEdit(null);
  }, [accountingType]);

  return (
    <div className="root">
      <HeaderButtons
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        buttons={buttons}
      />
      <div className="accountingPage">
        {activeButton === "Ajouter" && (
          <AccountingForm
            expenseToEdit={null}
            setExpenseToEdit={setExpenseToEdit}
          />
        )}
        {activeButton === "Modifier" &&
          (expenseToEdit ? (
            <AccountingForm
              expenseToEdit={expenseToEdit}
              setExpenseToEdit={setExpenseToEdit}
            />
          ) : (
            <AccountingList onEdit={handleEditAccounting} onEditPage={true} />
          ))}

        {activeButton === "Liste" && (
          <AccountingList onEdit={handleEditAccounting} />
        )}
        {activeButton === "Dashboard" && <Dashboard />}
      </div>
    </div>
  );
};
export default Accounting;
