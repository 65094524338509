import "./ContentPanel.scss";

import React, { useEffect, useRef, useState } from "react";

import useWindowWidth from "../../../hooks/useWindowWidth";

interface VideoItem {
  title: string;
  description: string;
  videoUrl: string;
  progress: number;
}

interface ContentPanelProps {
  videos: VideoItem[];
}

const ContentPanel: React.FC<ContentPanelProps> = ({ videos }) => {
  const [selectedVideo, setSelectedVideo] = useState<VideoItem | null>(null);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isTabelet } = useWindowWidth();

  useEffect(() => {
    setSelectedVideo(videos.length > 0 ? { ...videos[0], progress: 0 } : null);
  }, [videos]);

  useEffect(() => {
    const videoElement = videoRef.current;
    let rafId: number;

    const updateProgress = () => {
      if (videoElement) {
        const newProgress =
          (videoElement.currentTime / videoElement.duration) * 100;
        setProgress(newProgress);
        rafId = requestAnimationFrame(updateProgress);
      }
    };

    videoElement?.addEventListener(
      "play",
      () => (rafId = requestAnimationFrame(updateProgress)),
    );
    videoElement?.addEventListener("pause", () => cancelAnimationFrame(rafId));
    videoElement?.addEventListener("ended", () => cancelAnimationFrame(rafId));

    return () => {
      cancelAnimationFrame(rafId);
      videoElement?.removeEventListener(
        "play",
        () => (rafId = requestAnimationFrame(updateProgress)),
      );
      videoElement?.removeEventListener("pause", () =>
        cancelAnimationFrame(rafId),
      );
      videoElement?.removeEventListener("ended", () =>
        cancelAnimationFrame(rafId),
      );
    };
  }, [selectedVideo?.videoUrl]);

  return (
    <div className="contentPanel">
      <h2 className="contentPanelTitle">Comment ça marche ?</h2>
      <div className="pannelContainer">
        <div className="leftPanel">
          {videos.map((video, index) => (
            <div className="leftPanelRowContainer" key={index}>
              <div
                className={
                  selectedVideo?.title === video.title
                    ? "selectedVideo leftPanelRow"
                    : "leftPanelRow"
                }
                onClick={() => setSelectedVideo({ ...video, progress: 0 })}
              >
                <h1>
                  <span>{index + 1}</span> {video.title}
                </h1>
                <p className="videoDescription">{video.description}</p>
                <div className="progressBar">
                  <div
                    className="progress"
                    style={{ width: `${progress.toFixed(2)}%` }}
                  ></div>
                </div>
              </div>
              {isTabelet && selectedVideo && (
                <div
                  className={`videoContainer ${selectedVideo.title !== video.title ? "hiddenVideo" : ""}`}
                >
                  <video
                    ref={videoRef}
                    controls
                    src={selectedVideo.videoUrl}
                    autoPlay
                    loop
                    muted
                  />
                </div>
              )}
            </div>
          ))}
        </div>

        {!isTabelet && (
          <div className="rightPanel">
            {selectedVideo && (
              <div className="videoContainer">
                <video
                  ref={videoRef}
                  controls
                  src={selectedVideo.videoUrl}
                  autoPlay
                  loop
                  muted
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentPanel;
