import "./Organization.scss";

import React, { useState } from "react";
import { FaCog } from "react-icons/fa";

import HeaderButtons from "../../components/Utils/HeaderButtons/HeaderButtons";
import AddOrganizationUser from "./AddOrganizationUser/AddOrganizationUser";
import OrganizationInfos from "./OrganizationInfos/OrganizationInfos";
import OrganizationRequests from "./OrganizationRequests/OrganizationRequests";
import OrganizationSettings from "./OrganizationSettings/OrganizationSettings";

const Organization: React.FC = () => {
  const [activeButton, setActiveButton] = useState("Informations");

  const buttons = [
    { id: "Informations", render: "Informations" },
    { id: "Demandes", render: "Demandes" },
    { id: "Ajouter", render: "Ajouter" },
    { id: "Settings", render: <FaCog /> },
  ];

  return (
    <div className="root">
      <HeaderButtons
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        buttons={buttons}
      />
      <div className="organizationPage">
        {activeButton === "Demandes" && <OrganizationRequests />}
        {activeButton === "Informations" && <OrganizationInfos />}
        {activeButton === "Ajouter" && <AddOrganizationUser />}
        {activeButton === "Settings" && <OrganizationSettings />}
      </div>
    </div>
  );
};
export default Organization;
