import "./SaleForm.scss";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaPlus, FaTimes, FaUndo } from "react-icons/fa";

import Button from "../../../components/Utils/Button/Button";
import DynamicTable, {
  Column,
} from "../../../components/Utils/DynamicTable/DynamicTable";
import StyledDropdown from "../../../components/Utils/StyledDropdown/StyledDropdown";
import {
  StyledInputDate,
  StyledInputNumber,
  StyledInputText,
} from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import useSaleStore from "../../../stores/SaleStore";
import useUserStore from "../../../stores/UserStore";
import ClientType from "../../../types/Client";
import ProductType from "../../../types/Product";
import SaleType from "../../../types/Sale";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

interface SaleFormProps {
  saleToEdit: SaleType | null;
}

const SaleForm: React.FC<SaleFormProps> = ({ saleToEdit }) => {
  const { loadingStates, setLoading } = useLoadingStore();
  const { sale, setSale } = useSaleStore();
  const { isVatSubject } = useUserStore();

  const [products, setProducts] = useState<ProductType[]>([]);
  const [clientsList, setClientsList] = useState<ClientType[]>([]);

  const [availableProducts, setAvailableProducts] = useState<ProductType[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);

  useEffect(() => {
    // Cette fonction met à jour les produits disponibles en excluant ceux déjà dans la vente.
    const updateAvailableProducts = () => {
      const productIdsInSale = new Set(
        sale.products.map((p) => p.product?.product_id),
      );
      const filteredProducts = products.filter(
        (p) => !productIdsInSale.has(p.product_id),
      );
      setAvailableProducts(filteredProducts);
    };

    updateAvailableProducts();
  }, [sale.products, products]);

  useEffect(() => {
    const fetchData = async () => {
      const productsData = await appFetch("/api/products/list");
      if (productsData) {
        setProducts(productsData);
      }
      const clientsData = await appFetch("/api/clients/list");
      if (clientsData && !clientsData.unauthorized) {
        setClientsList(clientsData);
      }
    };
    fetchData();
  }, []);

  const setBuyDate = (value: Date) => {
    setSale((prevSale) => {
      return { ...prevSale, recorded_date: value };
    });
  };

  const setClient = (client: ClientType | undefined) => {
    if (!client) return;
    setSale((prevSale) => {
      return { ...prevSale, client: client };
    });
  };

  const createSale = async (type: string) => {
    // Vérifie si les champs obligatoires sont remplis
    if (sale.products.length === 0) {
      toast.error("Veuillez sélectionner au moins un produit ou un service.");
      return;
    }
    // Vérifie si un utilisateur a été sélectionné
    if (sale.client.client_id === -1) {
      toast.error("Veuillez sélectionner un client.");
      setLoading(type, false);
      return;
    }
    setLoading("createSale", true);

    const data = {
      products: sale.products,
      recorded_date: sale.recorded_date,
      name: sale.name,
      client_id: sale.client.client_id,
      sale_id: sale.sale_id,
    };

    try {
      const method = type === "createSale" ? "POST" : "PUT";
      const response = await appFetch(`/api/sale/${type}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response) {
        handleReset();
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Erreur lors de la création de la dépense :", error);
    }
    setLoading("createSale", false);
  };

  useEffect(() => {
    const fetchQuoteDetails = async () => {
      if (saleToEdit) {
        try {
          const saleDetailData = await appFetch(
            `/api/sale/getSaleDetails?sale_id=${saleToEdit.sale_id}`,
          );
          console.log(saleDetailData);
          if (saleDetailData && saleDetailData.sale) {
            setSale((prevSale) => ({
              ...prevSale,
              ...saleDetailData.sale,
              recorded_date: new Date(saleDetailData.sale.recorded_date),
              products: saleDetailData.sale.products,
              unit_price: saleDetailData.sale.unit_price,
              payment_method: saleDetailData.sale.payment_method,
              client: saleDetailData.sale.client,
            }));
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails du devis :",
            error,
          );
        }
      }
    };

    fetchQuoteDetails();
  }, [saleToEdit]);

  const handleReset = () => {
    setSale({
      sale_id: -1,
      name: "",
      products: [
        {
          product: undefined,
          quantity: 0,
          unit_price: 0,
          row_id: 0,
          tva_rate: 0,
        },
      ],
      recorded_date: new Date(),
      file_path: "",
      organization_id: -1,
      client: {
        client_id: -1,
        client_name: "",
      },
    });
  };
  const handleQuantityChange = (value: string | null, index: number) => {
    const updatedProducts = [...sale.products];
    const newQuantity = value === null ? 0 : Math.max(0, parseInt(value));

    if (updatedProducts[index]) {
      updatedProducts[index].quantity = newQuantity;
    }

    setSale((prevSale) => {
      return { ...prevSale, products: updatedProducts };
    });
  };

  const handlePriceChange = (value: string | null, index: number) => {
    const updatedProducts = [...sale.products];
    const newPrice = value === null ? 0 : Math.max(0, parseFloat(value));

    if (updatedProducts[index]) {
      updatedProducts[index].unit_price = newPrice;
      setSale((prevSale) => {
        return { ...prevSale, products: updatedProducts };
      });
    }
  };

  const handleTvaRateChange = (value: string | null, index: number) => {
    const updatedProducts = [...sale.products];
    const newTvaRate = value === null ? 0 : Math.max(0, parseFloat(value));

    if (updatedProducts[index]) {
      updatedProducts[index].tva_rate = newTvaRate;
      setSale((prevSale) => {
        return { ...prevSale, products: updatedProducts };
      });
    }
  };

  const addSaleProduct = () => {
    if (availableProducts.length > 0) {
      const newProduct = availableProducts[0];
      const newProductEntry = {
        product: undefined,
        quantity: 0,
        unit_price: 0,
        tva_rate: 0,
        row_id: Date.now(), // Utiliser le timestamp comme identifiant temporaire
      };
      setSale((prevSale) => ({
        ...prevSale,
        products: [...prevSale.products, newProductEntry],
      }));
      setAvailableProducts(
        availableProducts.filter((p) => p.product_id !== newProduct.product_id),
      );
    } else {
      toast.error("Tous les produits sont déjà ajoutés.");
    }
  };

  const deleteSaleProduct = (identifier: number) => {
    setSale((prevSale) => ({
      ...prevSale,
      products: prevSale.products.filter(
        (p) => p.product?.product_id !== identifier && p.row_id !== identifier,
      ),
    }));
    // Restaurer le produit à la liste disponible si nécessaire
    const deletedProduct = products.find((p) => p.product_id === identifier);
    if (deletedProduct) {
      setAvailableProducts([...availableProducts, deletedProduct]);
    }
  };
  useEffect(() => {
    setColumns([
      {
        title: "Produit",
        dataIndex: "product",
        render: (product: ProductType, _: undefined, index: number) => (
          <StyledDropdown
            label=""
            itemList={availableProducts}
            itemIdKey="product_id"
            itemLabelKey="name"
            value={product}
            setValue={(newProduct) => {
              const updatedProducts = sale.products.map((p, i) =>
                i === index ? { ...p, product: newProduct } : p,
              );
              setSale((prevSale) => ({
                ...prevSale,
                products: updatedProducts,
              }));
            }}
            placeholder="Sélectionnez un produit"
          />
        ),
      },
      {
        title: "Prix unitaire",
        dataIndex: "unit_price",
        render: (unit_price: number, _: undefined, index: number) => (
          <StyledInputNumber
            label=""
            value={unit_price?.toString() || ""}
            setValue={(value) => handlePriceChange(value, index)}
            className="priceInput"
          />
        ),
      },
      {
        title: "Quantité",
        dataIndex: "quantity",
        render: (quantity: number, _: undefined, index: number) => {
          let maxQuantity = "9999999";
          if (sale.products[index]) {
            const product = sale.products[index].product;
            maxQuantity =
              product && product.stock ? product.stock.toString() : "9999999";
          }

          return (
            <StyledInputNumber
              label=""
              value={quantity?.toString() || ""}
              setValue={(value) => handleQuantityChange(value, index)}
              max={maxQuantity}
              width="100px"
              className="quantityInput"
            />
          );
        },
      },
      ...(isVatSubject
        ? [
            {
              title: "TVA",
              dataIndex: "tva_rate",
              render: (tva_rate: number, _: undefined, index: number) => (
                <StyledInputNumber
                  label=""
                  value={tva_rate?.toString() || ""}
                  setValue={(value) => handleTvaRateChange(value, index)}
                  className="priceInput"
                  width="100px"
                />
              ),
            },
          ]
        : []),
      {
        title: "Actions",
        dataIndex: "product",
        render: (product: ProductType, _: undefined, index: number) => (
          <Button
            onClick={() =>
              deleteSaleProduct(
                product?.product_id || sale.products[index].row_id,
              )
            }
            icon={<FaTimes color="red" />}
            style="square"
            disabled={sale.products.length === 1}
          />
        ),
      },
    ]);
  }, [availableProducts, sale.products]); // Ajout de sale.products pour réagir aux changements des produits dans la vente

  return (
    <>
      <div className="saleSection">
        <div className="saleDetails">
          <StyledInputText
            label="Nom*"
            value={sale.name}
            setValue={(value: string) => {
              setSale((prevSale) => {
                return { ...prevSale, name: value };
              });
            }}
          />

          <StyledInputDate
            label="Date de vente*"
            value={sale.recorded_date}
            setValue={setBuyDate}
          />
          <StyledDropdown
            label="Client*"
            value={sale.client}
            setValue={(client) => setClient(client)}
            itemList={clientsList}
            itemLabelKey="client_name"
            itemIdKey="client_id"
          />
        </div>
        <div className="saleProducts">
          <DynamicTable columns={columns} data={sale.products} mobile={false} />
          <Button
            onClick={() => addSaleProduct()}
            icon={<FaPlus />}
            width={"100%"}
          ></Button>
        </div>
        {!saleToEdit && (
          <span className="buttonCreate">
            <Button
              onClick={() => {
                createSale("createSale");
              }}
              className="submitBtn"
              label={`Créer une vente`}
              isLoading={loadingStates.createSale}
            />
            <Button
              onClick={() => {
                handleReset();
              }}
              className="squaredButton"
              icon={<FaUndo />}
            />
          </span>
        )}
        {saleToEdit && (
          <Button
            onClick={() => {
              createSale("updateSale");
            }}
            className="submitBtn"
            label={`Modifier la vente`}
            isLoading={loadingStates.createSale}
          />
        )}
      </div>
    </>
  );
};
export default SaleForm;
