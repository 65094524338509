import "./Invoices.scss";

import InvoiceList from "./InvoiceList/InvoiceList";

const Invoices = () => {
  return (
    <div className="root">
      <div className="invoicesPage">
        <InvoiceList />
      </div>
    </div>
  );
};

export default Invoices;
