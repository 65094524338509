import { FiTrash2 } from "react-icons/fi";

import Button from "../Utils/Button/Button";
import Modal from "../Utils/Modal/Modal";

interface DeleteButtonProps {
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
}

const DeleteButton = ({ className, onClick, isLoading }: DeleteButtonProps) => {
  return (
    <Modal
      modalTitle="Etes vous sûr de vouloir supprimer?"
      modalContent={<div className="modalContent"></div>}
      modalButton={({ onClick }) => (
        <Button
          className="deleteButton"
          onClick={onClick}
          icon={<FiTrash2 />}
          style="square"
          width={40}
          height={40}
        />
      )}
      modalFooterButton={
        <Button
          className="deleteButton"
          onClick={onClick}
          label="Supprimer"
          isLoading={isLoading}
        />
      }
      modalFooterClass="buttonLogout"
      className={`${className} deleteButton`}
    />
  );
};

export default DeleteButton;
