// QuoteStore.tsx
import { create } from "zustand";

import ClientType from "../types/Client";
import { SelectedProduct } from "../types/Product";
import QuoteType from "../types/Quote";
import { SelectedService } from "../types/Service";

interface QuoteState {
  quote: QuoteType;
  setQuote: (quote: QuoteType | ((prevQuote: QuoteType) => QuoteType)) => void;
  selectedProducts: SelectedProduct[];
  setSelectedProducts: (
    products:
      | SelectedProduct[]
      | ((prevProducts: SelectedProduct[]) => SelectedProduct[]),
  ) => void;
  selectedServices: SelectedService[];
  setSelectedServices: (
    services:
      | SelectedService[]
      | ((prevServices: SelectedService[]) => SelectedService[]),
  ) => void;
  quoteActiveButton: string;
  setQuoteActiveButton: (quoteActiveButton: string) => void;
  quoteToEdit: QuoteType | null;
  setQuoteToEdit: (quote: QuoteType | null) => void;

  client: ClientType | undefined;
  setClient: (
    client:
      | ClientType
      | undefined
      | ((prevClient: ClientType | undefined) => ClientType),
  ) => void;
}

const useQuoteStore = create<QuoteState>((set) => ({
  // État initial du devis avec des valeurs par défaut
  quote: {
    quote_id: 0,
    client_id: 0,
    total_price: 0,
    status: "",
    user_decision: "",
    created_at: new Date(),
    updated_at: new Date(),
    valid_until: new Date(),
    rent_date: new Date(),
    reserved_from: new Date(),
    reserved_until: new Date(),
    coefficient: 1,
    description: "",
  },
  setQuote: (quote) =>
    set((state) => ({
      quote: typeof quote === "function" ? quote(state.quote) : quote,
    })),

  // État initial et setter pour les produits sélectionnés avec un produit vide par défaut
  selectedProducts: [
    {
      category_id: -1,
      created_at: "",
      image: "",
      name: "",
      price: "0",
      product_id: -1,
      stock: 0,
      subCategory_id: null,
      updated_at: "",
      quantity: 1,
      tva_rate: 0,
      discount: 0,
      row_id: new Date().getTime() * 2,
    },
  ],
  setSelectedProducts: (products) =>
    set((state) => ({
      selectedProducts:
        typeof products === "function"
          ? products(state.selectedProducts)
          : products,
    })),

  // État initial et setter pour les services sélectionnés avec un service vide par défaut
  selectedServices: [
    {
      service_id: -1,
      quantity: 1,
      name: "",
      description: "",
      price: "0",
      duration: "",
      row_id: new Date().getTime() * 3,
      tva_rate: 0,
      discount: 0,
      created_at: "",
      updated_at: "",
    },
  ],
  setSelectedServices: (services) =>
    set((state) => ({
      selectedServices:
        typeof services === "function"
          ? services(state.selectedServices)
          : services,
    })),

  // État initial et setter pour le bouton actif
  quoteActiveButton: "Créer",
  setQuoteActiveButton: (newActiveButton) =>
    set(() => ({ quoteActiveButton: newActiveButton })),
  quoteToEdit: null,
  setQuoteToEdit: (quote) => set(() => ({ quoteToEdit: quote })),

  // État initial et setter pour le client sélectionné
  client: undefined,
  setClient: (client) =>
    set((state) => ({
      client: typeof client === "function" ? client(state.client) : client,
    })),
}));

export default useQuoteStore;
