// FeaturesPresentation.tsx
import "./FeaturesPresentation.scss";

import {
  FaChartLine,
  FaDatabase,
  FaFileInvoiceDollar,
  FaMailBulk,
  FaNetworkWired,
  FaRegCalendarCheck,
  FaTags,
  FaUsers,
} from "react-icons/fa";

const FeaturesPresentation = () => {
  const features = [
    {
      title: "Gestion de clients",
      description: "Centralisez et gérez efficacement vos relations clients.",
      Icon: FaUsers,
    },
    {
      title: "Gestion de catalogue",
      description:
        "Un accès facile à votre catalogue de produits et services pour une gestion simplifiée.",
      Icon: FaTags,
    },
    {
      title: "Création de devis",
      description:
        "Générez des devis précis et personnalisés en quelques clics avec votre branding.",
      Icon: FaFileInvoiceDollar,
    },
    {
      title: "Création de factures",
      description:
        "Factures personnalisables à partir des devis avec votre branding.",
      Icon: FaFileInvoiceDollar,
    },
    {
      title: "Multi-utilisateur",
      description: "Collaborez en équipe avec des accès différenciés.",
      Icon: FaNetworkWired,
    },
    {
      title: "Gestion comptable",
      description:
        "Suivez vos flux financiers et exportez vos données pour une comptabilité à jour.",
      Icon: FaDatabase,
    },
    {
      title: "Création d'événements",
      description:
        "Organisez et gérez des événements directement depuis la plateforme.",
      Icon: FaRegCalendarCheck,
    },
    {
      title: "Sauvegardes Cloud",
      description:
        "Vos données sont dans le cloud afin d'éviter toute perte de données.",
      Icon: FaChartLine,
    },
    {
      title: "Envoi d'emails automatiques",
      description:
        "Automatisez vos communications et restez en contact avec vos clients. (À venir)",
      Icon: FaMailBulk,
    },
  ];

  return (
    <div className="featuresPresentation">
      <h2 className="featuresTitle">Pourquoi choisir notre solution ?</h2>
      <div className="featuresContainer">
        {features.map((feature, index) => (
          <div key={index} className="feature">
            <h2>
              <feature.Icon className="icon" />
              {feature.title}
            </h2>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesPresentation;
