// SaleStore.tsx
import { create } from "zustand";

import SaleType from "../types/Sale";

interface SaleState {
  saleActiveButton: string;
  setSaleActiveButton: (saleActiveButton: string) => void;
  saleToEdit: SaleType | null;
  setSaleToEdit: (sale: SaleType | null) => void;

  sale: SaleType;
  setSale: (sale: SaleType | ((prevSale: SaleType) => SaleType)) => void;
}

const useSaleStore = create<SaleState>((set) => ({
  // État initial et setter pour le bouton actif
  saleActiveButton: "Créer",
  setSaleActiveButton: (newActiveButton) =>
    set(() => ({ saleActiveButton: newActiveButton })),
  saleToEdit: null,
  setSaleToEdit: (sale) => set(() => ({ saleToEdit: sale })),

  // État initial de la vente
  sale: {
    sale_id: -1,
    name: "",
    products: [
      {
        product: undefined,
        quantity: 0,
        unit_price: 0,
        row_id: 0,
        tva_rate: 0,
      },
    ],
    recorded_date: new Date(),
    file_path: "",
    organization_id: -1,
    tva_rate: 21,
    client: { client_id: -1, client_name: "" },
  },

  setSale: (sale) =>
    set((state) => ({
      sale: typeof sale === "function" ? sale(state.sale) : sale,
    })),
}));

export default useSaleStore;
