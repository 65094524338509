import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { FaUndo } from "react-icons/fa";

import Button from "../../../../components/Utils/Button/Button";
import { ButtonSwitcherValue } from "../../../../components/Utils/ButtonSwitcher/ButtonSwitcher";
import StyledDropdown from "../../../../components/Utils/StyledDropdown/StyledDropdown";
import {
  StyledInputDate,
  StyledInputFile,
  StyledInputNumber,
  StyledInputText,
} from "../../../../components/Utils/StyledInput/StyledInput";
import useAccountingStore from "../../../../stores/AccountingStore";
import useLoadingStore from "../../../../stores/LoadingStore";
import AccountingRevenueType from "../../../../types/AccountingRevenue";
import EventType from "../../../../types/Event";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";

interface AccountingFormProps {
  revenueToEdit: AccountingRevenueType | null;
  revenueType: ButtonSwitcherValue;
  partyList: EventType[];
}

const Revenue: React.FC<AccountingFormProps> = ({
  revenueToEdit,
  revenueType,
  partyList,
}) => {
  // select the user for the quote
  const { loadingStates, setLoading } = useLoadingStore();
  const { revenue, setRevenue } = useAccountingStore();

  const setBuyDate = (value: Date) => {
    setRevenue((prevRevenue) => {
      return { ...prevRevenue, recorded_date: value };
    });
  };

  const createRevenue = async (type: string) => {
    // Vérifie si les champs obligatoires sont remplis

    if (
      !revenue.product_name ||
      !revenue.unit_price ||
      !revenue.recorded_date
    ) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return;
    }
    setLoading("createRevenue", true);

    // Prépare les données pour la requête
    const formData = new FormData();
    formData.append("product_name", revenue.product_name);
    formData.append("unit_price", revenue.unit_price.toString());
    formData.append("recorded_date", revenue.recorded_date.toISOString());
    if (revenue.payment_method) {
      formData.append("payment_method", revenue.payment_method);
    }
    formData.append("revenue_id", revenue.revenue_id.toString());
    formData.append("amount", revenue.amount.toString());
    if (revenue.event?.event_id)
      formData.append("event_id", revenue.event.event_id.toString());
    if (revenue.file) formData.append("file", revenue.file);
    try {
      const method = type === "addRevenue" ? "POST" : "PUT";
      const response = await appFetch(`/api/accounting/revenue/${type}`, {
        method: method,
        body: formData,
      });
      if (response) {
        handleReset();
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Erreur lors de la création de la dépense :", error);
    }
    setLoading("createRevenue", false);
  };

  useEffect(() => {
    const fetchQuoteDetails = async () => {
      if (revenueToEdit) {
        try {
          const revenueDetailData = await appFetch(
            `/api/accounting/revenue/getRevenueDetails?revenue_id=${revenueToEdit.revenue_id}`,
          );
          if (revenueDetailData && revenueDetailData.revenue) {
            const fileUrl = `/api/accounting/revenue/getRevenuesFile?revenue_id=${revenueDetailData.revenue.revenue.revenue_id}`;
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const file = new File(
              [blob],
              revenueDetailData.revenue.revenue.file_path,
              { type: blob.type },
            );

            setRevenue((prevRevenue) => ({
              ...prevRevenue,
              ...revenueDetailData.revenue.revenue,
              recorded_date: new Date(
                revenueDetailData.revenue.revenue.recorded_date,
              ),
              product_name: revenueDetailData.revenue.revenue.product_name,
              unit_price: revenueDetailData.revenue.revenue.unit_price,
              event: revenueDetailData.revenue.event,
              payment_method: revenueDetailData.revenue.revenue.payment_method,
              file: file,
            }));
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails du devis :",
            error,
          );
        }
      }
    };

    fetchQuoteDetails();
  }, [revenueToEdit]);

  const handleReset = () => {
    setRevenue({
      revenue_id: -1,
      product_name: "",
      unit_price: 0,
      amount: 0,
      recorded_date: new Date(),
      payment_method: "",
      file_path: "",
      file: null,
    });
  };

  return (
    <>
      <div className="accountingSection">
        <StyledInputText
          label="Nom*"
          value={revenue.product_name}
          setValue={(value: string) => {
            setRevenue((prevRevenue) => {
              return { ...prevRevenue, product_name: value };
            });
          }}
        />
        <StyledInputNumber
          label="Prix unitaire*"
          value={revenue.unit_price.toString()}
          setValue={(value: string | null) => {
            setRevenue((prevRevenue) => {
              return { ...prevRevenue, unit_price: Number(value) };
            });
          }}
        />
        <StyledInputNumber
          label="Quantité*"
          value={revenue.amount.toString()}
          setValue={(value: string | null) => {
            setRevenue((prevRevenue) => {
              return { ...prevRevenue, amount: Number(value) };
            });
          }}
        />

        <StyledInputDate
          label="Date d'entrée*"
          value={revenue.recorded_date}
          setValue={setBuyDate}
        />
        <StyledDropdown
          label="Événement"
          value={revenue.event}
          setValue={(value: EventType | undefined) => {
            setRevenue((prevRevenue) => {
              return { ...prevRevenue, event: value };
            });
          }}
          itemList={partyList}
          itemLabelKey="name"
          itemIdKey="event_id"
        />
        <StyledDropdown
          label="Moyen de paiement*"
          value={{ payment_method: revenue.payment_method }}
          setValue={(value: { payment_method: string } | undefined) => {
            setRevenue((prevRevenue) => {
              return {
                ...prevRevenue,
                payment_method: value?.payment_method ?? "",
              };
            });
          }}
          itemList={[
            { payment_method: "Cash" },
            { payment_method: "Carte bancaire" },
            { payment_method: "Virement" },
          ]}
          itemLabelKey="payment_method"
          itemIdKey="payment_method"
        />
        <StyledInputFile
          label="Justificatif*"
          setValue={(file: File | null) => {
            setRevenue((prevRevenue) => {
              return { ...prevRevenue, file: file };
            });
          }}
          value={revenue.file}
          className="accountingFileInput"
        />
      </div>
      <div className="accountingSection"></div>
      {!revenueToEdit && (
        <>
          <Button
            onClick={() => {
              createRevenue("addRevenue");
            }}
            className="submitBtn"
            label={`Ajouter la ${revenueType.id}`}
            isLoading={loadingStates.createRevenue}
          />
          <Button
            onClick={() => {
              handleReset();
            }}
            className="squaredButton"
            icon={<FaUndo />}
          />
        </>
      )}
      {revenueToEdit && (
        <Button
          onClick={() => {
            createRevenue("updateRevenue");
          }}
          className="submitBtn"
          label={`Modifider la ${revenueType.id}`}
          isLoading={loadingStates.createRevenue}
        />
      )}
    </>
  );
};
export default Revenue;
