import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface LogoutNavBarProps {
  className?: string;
}

const LogoutNavBar: React.FC<LogoutNavBarProps> = ({ className }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/");
  };

  return (
    <li className={`nav-item ${className}`}>
      <button className="nav-link" onClick={handleLogout}>
        <FaSignOutAlt />
        <span className="link-text">Déconnexion</span>
      </button>
    </li>
  );
};

export default React.memo(LogoutNavBar);
