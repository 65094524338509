import "./ModalContent.scss";

import Button from "../../../../components/Utils/Button/Button";
import {
  StyledInputDate,
  StyledInputFile,
  StyledInputText,
  StyledInputTime,
} from "../../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../../stores/LoadingStore";
import EventType from "../../../../types/Event";

interface ModalContentProps {
  setUpdatedEvent: React.Dispatch<React.SetStateAction<EventType>>;
  updatedEvent: EventType;
}
const ModalContent = ({ updatedEvent, setUpdatedEvent }: ModalContentProps) => {
  const handleChange =
    (key: keyof EventType) => (value: string | number | Date | null | File) => {
      setUpdatedEvent((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    };

  return (
    <div className="modalContent">
      <StyledInputText
        label="Nom*"
        value={updatedEvent.name}
        setValue={handleChange("name")}
      />
      <StyledInputText
        label="Lieu*"
        value={updatedEvent.location}
        setValue={handleChange("location")}
      />
      <StyledInputDate
        label="Date*"
        value={updatedEvent.date || ""}
        setValue={handleChange("date")}
      />
      <StyledInputTime
        label="Heure de début*"
        value={updatedEvent.start_at || ""}
        setValue={handleChange("start_at")}
      />
      <StyledInputTime
        label="Heure de fin*"
        value={updatedEvent.end_at || ""}
        setValue={handleChange("end_at")}
      />
      <StyledInputText
        label="Lien"
        value={updatedEvent.link}
        setValue={handleChange("link")}
      />
      <StyledInputFile
        label="Image"
        value={
          updatedEvent.picture instanceof File ? updatedEvent.picture : null
        }
        setValue={(file: File | null) => handleChange("picture")(file)}
      />
    </div>
  );
};

interface ModalFooterButtonProps {
  onClick?: () => void;
  type: "Créer" | "Modifier";
}

const ModalFooterButton: React.FC<ModalFooterButtonProps> = ({
  onClick,
  type,
}) => {
  const { loadingStates } = useLoadingStore();

  return (
    <>
      {onClick && (
        <Button
          className="modalFooterButton"
          onClick={onClick}
          label={type}
          type={"submit"}
          isLoading={loadingStates.addEvent}
        />
      )}
    </>
  );
};

export default ModalContent;
export { ModalFooterButton };
