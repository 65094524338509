import "./DynamicTable.scss";

import React from "react";

import useWindowWidth from "../../../hooks/useWindowWidth";

export interface Column {
  title: string;
  dataIndex: string;
  render?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    record: any,
    index: number,
  ) => JSX.Element | string | number;
}

interface DynamicTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  columns: Column[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (record: any) => void;
  mobile?: boolean;
}

const DynamicTable: React.FC<DynamicTableProps> = ({
  data,
  columns,
  onClick,
  mobile = true,
}) => {
  const { isTabelet } = useWindowWidth();

  if (isTabelet && mobile) {
    return (
      <div className="mobileTable">
        {data.map((record, rowIndex) => (
          <div
            key={rowIndex}
            className="card"
            style={{ width: "100%" }}
            onClick={() => (onClick ? onClick(record) : undefined)}
          >
            {columns.map((col, colIndex) => (
              <div key={colIndex} className="card-content">
                <strong>{col.title}:</strong>
                {col.render
                  ? col.render(record[col.dataIndex], record, rowIndex)
                  : record[col.dataIndex]}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <table className="table">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((record, rowIndex) => (
            <tr
              key={rowIndex}
              onClick={() => (onClick ? onClick(record) : undefined)}
            >
              {columns.map((col, colIndex) => (
                <td key={colIndex}>
                  {col.render
                    ? col.render(record[col.dataIndex], record, rowIndex)
                    : record[col.dataIndex]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
};

export default DynamicTable;
