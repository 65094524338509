import "./StyledInput.scss";

import React, { useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
// Composant de base
interface StyledInputBaseProps<T> {
  label: string;
  value: T;
  setValue: (value: T) => void;
  type: string;
  children: React.ReactNode;
  className?: string;
  width?: string;
}

const StyledInputBase: React.FC<StyledInputBaseProps<any>> = ({
  label,
  children,
  className,
  width,
}) => {
  return (
    <div className={`styledInput ${className || ""}`} style={{ width }}>
      {label !== "" && <label>{label}</label>}
      {children}
    </div>
  );
};

export const StyledInputText: React.FC<{
  label: string;
  value: string;
  placeholder?: string;
  setValue: (value: string) => void;
  required?: boolean;
  className?: string;
}> = ({ label, value, setValue, required, className, placeholder }) => (
  <StyledInputBase label={label} value={value} setValue={setValue} type="text">
    <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder ?? label}
      required={required}
      className={className ?? ""}
    />
  </StyledInputBase>
);

export const StyledInputNumber: React.FC<{
  label: string;
  value: string | null;
  setValue: (value: string | null) => void;
  max?: string;
  min?: string;
  width?: string;
  className?: string;
}> = ({ label, value, setValue, max, min, width, className }) => {
  const [inputStrValue, setInputStrValue] = useState<string>(value || "");
  const [isMaxExceeded, setIsMaxExceeded] = useState<boolean>(false);

  useEffect(() => {
    setInputStrValue(value || "");
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    // Check if input is a valid number with either dot or comma
    const isValidInput = /^(\d+([.,]\d*)?)?$/.test(inputValue);

    if (!isValidInput) return; // Don't process invalid input

    // Replace comma with dot
    inputValue = inputValue.replace(/,/g, ".");

    setInputStrValue(inputValue);

    // if > max, set to max
    if (max && parseFloat(inputValue) > parseFloat(max)) {
      setValue(max);
      setInputStrValue(max);
      // Définir la bordure en rouge
      setIsMaxExceeded(true);

      // Réinitialiser après 1 seconde
      setTimeout(() => {
        setIsMaxExceeded(false);
      }, 1000);
      return;
    }

    if (inputValue !== "") {
      setValue(inputValue);
    } else {
      setValue(null);
      setInputStrValue("");
    }
  };

  return (
    <StyledInputBase
      label={label}
      value={value}
      setValue={setValue}
      type="number"
      className={
        isMaxExceeded ? "invalid " + (className ?? "") : "" + (className ?? "")
      }
      width={width}
    >
      <input
        type="number"
        value={inputStrValue}
        min={min}
        max={max}
        onChange={handleInputChange}
      />
    </StyledInputBase>
  );
};

export const StyledInputDate: React.FC<{
  label: string;
  value: Date;
  setValue: (value: Date) => void;
}> = ({ label, value, setValue }) => (
  <StyledInputBase label={label} value={value} setValue={setValue} type="date">
    <input
      type="date"
      value={value.toISOString().split("T")[0]}
      onChange={(e) => setValue(new Date(e.target.value))}
    />
  </StyledInputBase>
);

export const StyledTextArea: React.FC<{
  label: string;
  value: string;
  setValue: (value: string) => void;
}> = ({ label, value, setValue }) => (
  <StyledInputBase
    label={label}
    value={value}
    setValue={setValue}
    type="text"
    className="textArea"
  >
    <textarea value={value} onChange={(e) => setValue(e.target.value)} />
  </StyledInputBase>
);

export const StyledInputEmail: React.FC<{
  label: string;
  value: string;
  setValue: (value: string) => void;
  required?: boolean;
  className?: string;
}> = ({ label, value, setValue, required, className }) => (
  <StyledInputBase label={label} value={value} setValue={setValue} type="email">
    <input
      type="email"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={label}
      required={required}
      className={className ?? ""}
    />
  </StyledInputBase>
);

export const StyledInputPassword: React.FC<{
  label: string;
  value: string;
  setValue: (value: string) => void;
  required?: boolean;
  className?: string;
}> = ({ label, value, setValue, required, className }) => {
  const [inputType, setInputType] = useState("password");

  const toggleVisibility = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <StyledInputBase
      label={label}
      value={value}
      setValue={setValue}
      type={inputType}
    >
      <div className="inputGroup">
        <input
          type={inputType}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={label}
          required={required}
          className={`${className ?? ""}`}
        />
        <div className="inputHideElement" onClick={toggleVisibility}>
          {inputType === "password" ? <FiEyeOff /> : <FiEye />}
        </div>
      </div>
    </StyledInputBase>
  );
};

export const StyledInputFile: React.FC<{
  label: string;
  setValue: (file: File | null) => void;
  value?: File | null | string;
  required?: boolean;
  accept?: string;
  className?: string;
}> = ({ label, setValue, required, accept, className, value }) => {
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setValue(file);

    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setFilePreview(previewUrl);
      setFileType(file.type);
    } else if (typeof value === "string") {
      setFilePreview(value);
      return;
    } else {
      setFilePreview(null);
      setFileType(null);
    }
  };

  useEffect(() => {
    if (typeof value === "string") {
      setFilePreview(value);
      setFileType("image/png");
      return;
    }
    if (value) {
      const previewUrl = URL.createObjectURL(value);
      setFilePreview(previewUrl);
      setFileType(value.type);
    }
    if (!value) {
      setFilePreview(null);
      setFileType(null);
    }
  }, [value]);

  return (
    <StyledInputBase
      label={label}
      value={""}
      setValue={setValue}
      type="file"
      className={className ?? ""}
    >
      <input
        type="file"
        onChange={handleFileChange}
        required={required}
        accept={accept}
      />
      {filePreview && fileType && (
        <div className="filePreview">
          {fileType === "application/pdf" ? (
            <embed src={filePreview} width="100%" height="400px" />
          ) : /^image\/(jpeg|png|svg\+xml)$/.test(fileType) ? (
            <img
              src={filePreview}
              alt="Aperçu du fichier"
              style={{ maxWidth: "100%", maxHeight: "400px" }}
            />
          ) : (
            <p>Aperçu non disponible pour ce type de fichier.</p>
          )}
        </div>
      )}
    </StyledInputBase>
  );
};

export const StyledInputTime: React.FC<{
  label: string;
  value: string;
  setValue: (value: string) => void;
  required?: boolean;
  className?: string;
}> = ({ label, value, setValue, required, className }) => (
  <StyledInputBase label={label} value={value} setValue={setValue} type="time">
    <input
      type="time"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      required={required}
      className={className ?? ""}
    />
  </StyledInputBase>
);

interface StyledInputPhoneProps {
  label: string;
  value: string;
  setValue: (value: string) => void;
  required?: boolean;
  className?: string;
}

export const StyledInputPhone: React.FC<StyledInputPhoneProps> = ({
  label,
  value,
  setValue,
  required,
  className,
}) => {
  const defaultPrefix = "+32-";
  // Extraction du préfixe et du numéro à partir de la valeur
  const prefix = value.includes("-")
    ? value.substring(0, value.indexOf("-") + 1)
    : defaultPrefix;
  const number = value.includes("-")
    ? value.substring(value.indexOf("-") + 1)
    : value;

  const handlePrefixChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(`${e.target.value}${number}`);
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(`${prefix}${e.target.value}`);
  };
  const prefixOptions = [
    { value: "+32-", label: "+32" },
    { value: "+33-", label: "+33" },
  ];

  return (
    <StyledInputBase
      label={label}
      value={value}
      setValue={setValue}
      type="phone"
    >
      <div className={`inputGroup ${className || ""}`}>
        <select
          value={prefix}
          onChange={handlePrefixChange}
          className="prefixSelect"
        >
          {prefixOptions.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <input
          type="tel"
          value={number}
          onChange={handleNumberChange}
          placeholder="Numéro de téléphone"
          required={required}
        />
      </div>
    </StyledInputBase>
  );
};
