// Benefits.tsx
import "./Benefits.scss";
const Benefits = () => {
  return (
    <div className="benefits">
      <h2 className="benefitsTitle">Arrêtez de perdre du temps!</h2>
      <div className="benefitsContainer">
        <p>
          Notre plateforme transforme la complexité de la gestion
          d&apos;entreprise en un processus <span>simple et fluide</span>,{" "}
          offrant <span>rapidité</span>,
          <span> simplicité d&apos;utilisation</span>, et une{" "}
          <span>centralisation</span> des informations cruciales. Economisez du
          temps et des ressources,
          <span>
            {" "}
            tout en gardant une vue d&apos;ensemble précise de votre activité
          </span>
          .
        </p>
      </div>
    </div>
  );
};

export default Benefits;
