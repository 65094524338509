import "./Button.scss";

import React, { ReactNode } from "react";
import toast from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

type ButtonProps = {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  style?: "blue" | "white" | "transparent" | "line" | "square" | "inverted";
  className?: string;
  label?: string;
  icon?: ReactNode;
  isLoading?: boolean;
  height?: number | string;
  width?: number | string;
  margin?: string;
  padding?: string;
  href?: string;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  className,
  label,
  icon,
  type = "button",
  isLoading,
  href,
  height,
  width,
  margin,
  padding,
  style,
  disabled,
}) => {
  const navigate = useNavigate();
  const isExternalLink = (url: string) => {
    return /^(http|https|mailto):/.test(url);
  };

  const handleClick = () => {
    if (isLoading) {
      toast.error("Opération en cours");
      return;
    }
    if (href) {
      if (isExternalLink(href)) {
        window.open(href, "_blank");
      } else {
        navigate(href);
      }
    } else if (onClick) {
      onClick();
    }
  };
  return (
    <button
      onClick={handleClick}
      className={`${className ?? ""} styledButton ${style}`}
      type={type}
      style={{
        height: height,
        minHeight: height,
        width: width,
        minWidth: width,
        margin: margin,
        padding: padding,
      }}
      disabled={disabled}
    >
      {isLoading ? (
        <FaSpinner className="loaderButton" />
      ) : (
        <>
          {label}
          {icon}
        </>
      )}
    </button>
  );
};

export default Button;
