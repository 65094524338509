// DropdownStore.tsx
import { create } from "zustand";

interface DropdownState {
  openDropdownId: string | null;
  setOpenDropdownId: (id: string | null) => void;
}

const useDropdownStore = create<DropdownState>((set) => ({
  openDropdownId: null,
  setOpenDropdownId: (id) => set({ openDropdownId: id }),
}));

export default useDropdownStore;
