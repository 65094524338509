import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiDownload } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";

import DeleteButton from "../../../../components/DeleteButton/DeleteButton";
import Button from "../../../../components/Utils/Button/Button";
import DynamicTable from "../../../../components/Utils/DynamicTable/DynamicTable";
import useLoadingStore from "../../../../stores/LoadingStore";
import AccountingRevenueType from "../../../../types/AccountingRevenue";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";

const RevenueList: React.FC<{
  onEdit: (revenue: AccountingRevenueType) => void;
  onEditPage?: boolean;
  search: string;
  startDate: Date;
  endDate: Date;
}> = ({ onEdit, onEditPage, search, startDate, endDate }) => {
  const [revenue, setRevenue] = useState<AccountingRevenueType[]>();
  const { loadingStates, setLoading } = useLoadingStore();

  useEffect(() => {
    const fetchData = async () => {
      const revenuesData = await appFetch(
        "/api/accounting/revenue/getRevenues",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
          }),
        },
      );
      if (revenuesData) {
        console.log(revenuesData.revenues);
        setRevenue(revenuesData.revenues);
      }
    };

    fetchData();
  }, [endDate, startDate]);

  const filteredRevenues = revenue?.filter((revenue) => {
    return (
      revenue.product_name.toLowerCase().includes(search.toLowerCase()) ||
      revenue.event?.name?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const downloadRevenue = async (revenue: AccountingRevenueType) => {
    setLoading(`downloadRevenue${revenue.revenue_id}`, true);
    try {
      const fileUrl = `/api/accounting/revenue/getRevenuesFile?revenue_id=${revenue.revenue_id}`;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = revenue.product_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("Erreur lors du téléchargement du fichier");
    }
    setLoading(`downloadRevenue${revenue.revenue_id}`, false);
  };

  const deleteRevenue = async (revenueId: number) => {
    setLoading(`deleteRevenue${revenueId}`, true);
    const response = await appFetch(
      `/api/accounting/revenue/deleteRevenue?revenue_id=${revenueId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ revenue_id: revenueId }),
      },
    );

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      toast.success(response.message);

      setRevenue((prev) => {
        if (prev) {
          return prev.filter((revenue) => revenue.revenue_id !== revenueId);
        }
        return prev;
      });
    }
    setLoading(`deleteRevenue${revenueId}`, false);
  };

  let columns = [
    {
      title: "Nom",
      dataIndex: "product_name",
    },
    {
      title: "Date",
      dataIndex: "recorded_date",
      render: (value: string) => {
        return new Date(value).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
      },
    },
    {
      title: "Mode de paiement",
      dataIndex: "payment_method",
    },
    {
      title: "Event",
      dataIndex: "event",
      render: (value: { name: string }) => {
        return value.name;
      },
    },
    {
      title: "Prix unitaire",
      dataIndex: "unit_price",
      render: (value: number) => {
        return `${value} €`;
      },
    },
    {
      title: "Quantité",
      dataIndex: "amount",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, record: AccountingRevenueType) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            className="downloadButton"
            onClick={() => downloadRevenue(record)}
            icon={<BiDownload />}
            isLoading={loadingStates[`downloadRevenue${record.revenue_id}`]}
            style="square"
          />
          <Button
            className="editButton"
            onClick={() => onEdit(record)}
            icon={<FiEdit2 />}
            style="square"
          />

          <DeleteButton
            onClick={() => deleteRevenue(record.revenue_id)}
            isLoading={loadingStates[`deleteRevenue${record.revenue_id}`]}
          />
        </div>
      ),
    },
  ];
  if (onEditPage) {
    columns = columns.filter((column) => column.title !== "Actions");
  }

  const handleRowClick = (expense: AccountingRevenueType) => {
    if (onEditPage) {
      onEdit(expense);
    }
  };

  return (
    <div className={`accountingListPage ${onEditPage ? "onEditPage" : ""}`}>
      <div className="tableContainer">
        <DynamicTable
          data={filteredRevenues ?? []}
          columns={columns}
          onClick={handleRowClick}
        />
      </div>
    </div>
  );
};

export default RevenueList;
