import toast from "react-hot-toast";
import { NavigateFunction } from "react-router-dom";

async function appFetch(
  route: string,
  options?: RequestInit,
  navigate?: NavigateFunction,
) {
  try {
    const response = await fetch(route, {
      ...options,
      credentials: "include",
    });

    // Handle too many requests
    if (response.status === 429) {
      toast.error("Trop de requêtes, veuillez réessayer plus tard");
      return null;
    }

    // Handle no organization
    if (response.status === 401) {
      if (navigate) {
        navigate("/organizationSetup");
      }
      return { message: "Vous n'avez pas de'organisation", error: 401 };
    }

    const contentType = response.headers.get("content-type");

    // Handle forbidden access
    if (response.status === 403) {
      return null;
    }
    if (response.status === 402) {
      if (navigate) {
        navigate("/payment/create");
      }

      return { message: "Payment required" };
    }

    // Handle successful response
    if (response.ok) {
      if (
        (contentType && contentType.indexOf("application/pdf") !== -1) ||
        (contentType && contentType === "image/png") ||
        (contentType && contentType === "application/zip")
      ) {
        return await response.blob();
      } else {
        return await response.json();
      }
    } else {
      try {
        const responseData = await response.json();
        toast.error(responseData.message || responseData.error);
        return null;
      } catch (error) {
        return null;
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      toast.error(error.message);
    } else {
      toast.error("Une erreur inconnue s'est produite");
    }
    throw error;
  }
}

export default appFetch;
