import "./Login.scss";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Button from "../../components/Utils/Button/Button";
import ButtonSwitcher, {
  ButtonSwitcherValue,
} from "../../components/Utils/ButtonSwitcher/ButtonSwitcher";
import {
  StyledInputEmail,
  StyledInputPassword,
  StyledInputText,
} from "../../components/Utils/StyledInput/StyledInput";
import useUserStore from "../../stores/UserStore";
import appFetch from "../../Utils/Services/Fetch/appFetch";
import GmailButton from "./GmailButton/GmailButton";

const Login: React.FC = () => {
  const [loginType, setLoginType] = useState<ButtonSwitcherValue>({
    id: "Login",
    name: "Se connecter",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { isAuthenticated } = useUserStore();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loginType.id === "Register" && password !== confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas");
      return;
    }
    const route =
      loginType.id === "Login" ? "/api/auth/login" : "/api/auth/register";
    const body =
      loginType.id === "Login"
        ? { email, password }
        : { name, email, password };

    try {
      const response = await appFetch(
        route,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        },
        navigate,
      );

      if (response) {
        // redirect to home page

        if (response.error === 401) {
          navigate("/organizationSetup");
          return;
        } else if (loginType.id === "Login") {
          navigate("/quote");
        } else {
          navigate("/organizationSetup");
        }

        toast.success(response.message);
      } else {
        toast.error("Mot de passe ou email incorrect");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // reset fields
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setName("");
  }, [loginType]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/quote");
    }
    console.log("isAuthenticated", isAuthenticated);
  }, [isAuthenticated]);

  return (
    <div className="loginRoot">
      <div className="loginPage">
        <ButtonSwitcher
          datas={[
            { id: "Login", name: "Se connecter" },
            { id: "Register", name: "S'inscrire" },
          ]}
          setValue={setLoginType}
          value={loginType}
          className="secretsPageButtonSwitch"
        />
        <form onSubmit={handleSubmit}>
          <StyledInputEmail
            label="Email*"
            value={email}
            setValue={setEmail}
            required={true}
            className="inputLogin"
          />
          <StyledInputPassword
            label="Mot de passe*"
            value={password}
            setValue={setPassword}
            required={true}
            className="inputLogin"
          />
          {loginType.id === "Register" && (
            <StyledInputPassword
              label="Confirmer le mot de passe*"
              value={confirmPassword}
              setValue={setConfirmPassword}
              required={true}
              className="inputLogin"
            />
          )}
          {loginType.id === "Register" && (
            <StyledInputText
              label="Nom*"
              value={name}
              setValue={setName}
              required={true}
              className="inputLogin"
            />
          )}
          <Button
            type="submit"
            label={loginType.name}
            className="loginSubmitButton"
          />
          <GmailButton loginType={loginType} />
        </form>
        <Button
          label="Mot de passe oublié ?"
          onClick={() => navigate("/forgotPassword")}
          className="forgotPasswordButton"
          style="transparent"
          width={"max-content"}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Login;
