import "./OrganizationSetup.scss";

import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";

import Button from "../../components/Utils/Button/Button";
import ButtonSwitcher, {
  ButtonSwitcherValue,
} from "../../components/Utils/ButtonSwitcher/ButtonSwitcher";
import CreateOrganization from "./CreateOrganization/CreateOrganization";
import JoinOrganization from "./JoinOrganization/JoinOrganization";

const OrganizationSetup: React.FC = () => {
  const [organizationSetupType, setOrganizationSetupType] =
    useState<ButtonSwitcherValue>({
      id: "Créer",
      name: "Créer",
    });

  return (
    <div className="organizationSetupRoot">
      <div className="organizationSetupPage">
        <div className="organizationSetupPageHeader">
          <Button className="backButton" icon={<FaArrowLeft />} href="/login" />
          <h3>{organizationSetupType.name} une organisation</h3>
        </div>
        <ButtonSwitcher
          datas={[
            { id: "Créer", name: "Créer" },
            { id: "Rejoindre", name: "Rejoindre" },
          ]}
          setValue={setOrganizationSetupType}
          value={organizationSetupType}
          className="secretsPageButtonSwitch"
        />
        {organizationSetupType.id === "Créer" ? (
          <CreateOrganization />
        ) : (
          <JoinOrganization />
        )}
      </div>
    </div>
  );
};

export default OrganizationSetup;
