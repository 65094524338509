import "./ClientList.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";

import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Button from "../../../components/Utils/Button/Button";
import DynamicTable from "../../../components/Utils/DynamicTable/DynamicTable";
import Modal from "../../../components/Utils/Modal/Modal";
import Spinner from "../../../components/Utils/Spinner/Spinner";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import ClientType from "../../../types/Client";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import ModalContent, { ModalFooterButton } from "./ModalContent/ModalContent";

const ClientList = () => {
  const [clients, setClients] = useState<ClientType[]>();
  const [search, setSearch] = useState("");
  const [updatedClient, setUpdatedClient] = useState<ClientType>({
    client_id: -1,
    client_name: "",
    client_email: "",
    client_address: "",
    client_phone: "",
    bank_account: "",
    vat_number: "",
  });
  const { loadingStates, setLoading } = useLoadingStore();

  useEffect(() => {
    const fetchData = async () => {
      setLoading("getClients", true);
      const clientData = await appFetch("/api/clients/list");
      if (clientData) {
        setClients(clientData);
      }
      setLoading("getClients", false);
    };

    fetchData();
  }, []);

  const filteredUsers = clients?.filter((client) => {
    return (
      client.client_name?.toLowerCase().includes(search.toLowerCase()) ||
      client.client_id.toString().includes(search.toLowerCase()) ||
      client.client_email?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const deleteClient = async (clientId: number) => {
    setLoading("deleteClient" + clientId, true);
    const response = await appFetch(`/api/clients/deleteClient`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ client_id: clientId }),
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      const clientData = await appFetch("/api/clients/list");
      if (clientData) {
        setClients(clientData);
      }
    }
    setLoading("deleteClient" + clientId, false);
  };

  const createClient = async () => {
    if (!updatedClient.client_name) {
      toast.error("Veuillez saisir le nom du client.");
      return;
    }
    setLoading("createClient", true);
    const response = await appFetch(`/api/clients/createClient`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...updatedClient,
      }),
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      const clientData = await appFetch("/api/clients/list");
      if (clientData) {
        setClients(clientData);
      }
    }
    setLoading("createClient", false);
  };

  const updateClient = async (client_id: number) => {
    const {
      client_name,
      client_email,
      client_address,
      client_phone,
      bank_account,
      vat_number,
    } = updatedClient;
    // Assurez-vous que les données nécessaires sont présentes
    if (!client_id || client_id === -1) {
      toast.error("ID de l'utilisateur non spécifié.");
      return;
    }
    setLoading("createClient", true);

    const response = await appFetch(`/api/clients/updateClient`, {
      method: "PUT", // Utilisez PUT pour une mise à jour
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id,
        client_name,
        client_email,
        client_address,
        client_phone,
        bank_account,
        vat_number,
      }),
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      const clientData = await appFetch("/api/clients/list");
      if (clientData) {
        setClients(clientData);
      }
    }
    setLoading("createClient", false);
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "client_name",
    },
    {
      title: "Email",
      dataIndex: "client_email",
    },
    {
      title: "Numéro",
      dataIndex: "client_phone",
    },
    {
      title: "Compte",
      dataIndex: "bank_account",
    },
    {
      title: "TVA",
      dataIndex: "vat_number",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, client: ClientType) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Modal
            modalTitle="Modifier l'utilisateur"
            modalContent={
              <ModalContent
                setUpdatedClient={setUpdatedClient}
                updatedClient={updatedClient}
              />
            }
            modalButton={({ onClick }) => (
              <Button
                icon={<FiEdit2 />}
                onClick={onClick}
                className="editButton"
                style="square"
              />
            )}
            modalFooterButton={
              <ModalFooterButton
                onClick={() => updateClient(client.client_id)}
                type="Modifier"
              />
            }
            onClick={() => setUpdatedClient(client)}
          />

          <DeleteButton
            onClick={() => deleteClient(client.client_id)}
            isLoading={loadingStates["deleteClient" + client.client_id]}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={`clientListPage`}>
      <div className="clientHeader">
        <StyledInputText
          label="Rechercher"
          value={search}
          setValue={setSearch}
          className="searchInput"
        />
        <Modal
          modalTitle="Créer un client"
          modalContent={
            <ModalContent
              setUpdatedClient={setUpdatedClient}
              updatedClient={updatedClient}
            />
          }
          modalButton={({ onClick }) => (
            <Button label="Créer" onClick={onClick} className="editButton" />
          )}
          modalFooterButton={
            <ModalFooterButton onClick={() => createClient()} type="Créer" />
          }
          onClick={() => {
            setUpdatedClient({
              client_id: -1,
              client_name: "",
              client_email: "",
              client_address: "",
              client_phone: "",
              bank_account: "",
              vat_number: "",
            });
          }}
        />
      </div>
      <div className="tableContainer">
        {loadingStates.getClients ? (
          <Spinner />
        ) : (
          <DynamicTable data={filteredUsers ?? []} columns={columns} />
        )}
      </div>
    </div>
  );
};

export default ClientList;
