import "./react-datepicker.scss";
import "./Calendar.scss";

import { fr } from "date-fns/locale/fr";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";

registerLocale("fr", fr);

interface CalendarProps {
  startDate: Date | null;
  endDate: Date | null;
  setDateRange: (update: [Date, Date]) => void;
}

const Calendar: React.FC<CalendarProps> = ({
  startDate,
  endDate,
  setDateRange,
}) => {
  return (
    <div className="calendarDateSelect">
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update: [Date, Date]) => {
          setDateRange(update);
        }}
        isClearable={true}
        locale="fr"
      />
    </div>
  );
};

export default Calendar;
