import {
  FaBook,
  FaBuilding,
  FaCalendarAlt,
  FaDollarSign,
  FaFileAlt,
  FaFileInvoiceDollar,
  FaHome,
  FaUsers,
} from "react-icons/fa";

const navItems = [
  { href: "/quote", text: "Devis", icon: <FaFileAlt />, className: "Devis" },
  {
    href: "/sales",
    text: "Ventes",
    icon: <FaDollarSign />,
    className: "Devis",
  },
  {
    href: "/invoices",
    text: "Factures",
    icon: <FaFileInvoiceDollar />,
    className: "Devis",
  },
  { href: "/accounting", text: "Compta", icon: <FaBook />, className: "Devis" },
  {
    href: "/catalogue",
    text: "Catalogue",
    icon: <FaHome />,
    className: "Devis",
  },

  {
    href: "/clients",
    text: "Clients",
    icon: <FaUsers />,
    className: "Devis",
  },
  {
    href: "/events",
    text: "Événements",
    icon: <FaCalendarAlt />,
    className: "Devis",
  },
  {
    href: "/organization",
    text: "Organisation",
    icon: <FaBuilding />,
    className: "Devis",
  },
];

export default navItems;
