import toast from "react-hot-toast";

import Button from "../../../../components/Utils/Button/Button";
import useLoadingStore from "../../../../stores/LoadingStore";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";

const ModalContent = () => {
  return (
    <div className="modalContent">
      <span> Etes vous sur de vouloir quitter l&lsquo;organisation ?</span>
    </div>
  );
};

const ModalFooterButton = () => {
  const { loadingStates, setLoading } = useLoadingStore();
  const handleLeave = async () => {
    setLoading("leaveOrganization", true);
    const response = await appFetch(`/api/organization/leave`, {
      method: "PUT",
    });
    if (response) {
      toast.success(response.message);
      // reload page
      window.location.reload();
    }
    setLoading("leaveOrganization", false);
  };
  return (
    <Button
      className="modalFooterButton"
      onClick={handleLeave}
      label={"Quitter"}
      isLoading={loadingStates.leaveOrganization}
    />
  );
};

export default ModalContent;
export { ModalFooterButton };
