import "./ModalContent.scss";

import Button from "../../../../components/Utils/Button/Button";
import {
  StyledInputEmail,
  StyledInputPhone,
  StyledInputText,
} from "../../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../../stores/LoadingStore";
import ClientType from "../../../../types/Client";

interface ModalContentProps {
  setUpdatedClient: React.Dispatch<React.SetStateAction<ClientType>>;
  updatedClient: ClientType;
}
const ModalContent = ({
  updatedClient,
  setUpdatedClient,
}: ModalContentProps) => {
  const handleChange =
    (key: keyof ClientType) => (value: string | number | File | null) => {
      setUpdatedClient((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    };

  return (
    <div className="modalContent createUser">
      <StyledInputText
        label="Nom*"
        value={updatedClient.client_name}
        setValue={handleChange("client_name")}
      />
      <StyledInputEmail
        label="Email"
        value={updatedClient.client_email || ""}
        setValue={handleChange("client_email")}
      />
      <StyledInputText
        label="Adresse"
        value={updatedClient.client_address || ""}
        setValue={handleChange("client_address")}
      />

      <StyledInputPhone
        label="Numéro de téléphone"
        value={updatedClient.client_phone || ""}
        setValue={handleChange("client_phone")}
      />

      <StyledInputText
        label="Numéro de compte"
        value={updatedClient.bank_account || ""}
        setValue={handleChange("bank_account")}
      />
      <StyledInputText
        label="Numéro de TVA"
        value={updatedClient.vat_number || ""}
        setValue={handleChange("vat_number")}
      />
    </div>
  );
};

interface ModalFooterButtonProps {
  onClick?: () => void;
  type: "Créer" | "Modifier";
}

const ModalFooterButton: React.FC<ModalFooterButtonProps> = ({
  onClick,
  type,
}) => {
  const { loadingStates } = useLoadingStore();
  return (
    <>
      {onClick && (
        <Button
          className="modalFooterButton"
          onClick={onClick}
          label={type}
          type={"submit"}
          isLoading={loadingStates.createClient}
        />
      )}
    </>
  );
};

export default ModalContent;
export { ModalFooterButton };
