import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaUndo } from "react-icons/fa";

import Button from "../../../../components/Utils/Button/Button";
import { ButtonSwitcherValue } from "../../../../components/Utils/ButtonSwitcher/ButtonSwitcher";
import StyledDropdown from "../../../../components/Utils/StyledDropdown/StyledDropdown";
import {
  StyledInputDate,
  StyledInputFile,
  StyledInputNumber,
  StyledInputText,
} from "../../../../components/Utils/StyledInput/StyledInput";
import useAccountingStore from "../../../../stores/AccountingStore";
import useLoadingStore from "../../../../stores/LoadingStore";
import AccountingExpenseType from "../../../../types/AccountingExpense";
import EventType from "../../../../types/Event";
import User from "../../../../types/User";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";

interface AccountingFormProps {
  expenseToEdit: AccountingExpenseType | null;
  expenseType: ButtonSwitcherValue;
  partyList: EventType[];
}

const Expense: React.FC<AccountingFormProps> = ({
  expenseToEdit,
  expenseType,
  partyList,
}) => {
  // select the user for the quote
  const [usersList, setUsersList] = useState<User[]>([]);

  const { loadingStates, setLoading } = useLoadingStore();
  const { expense, setExpense } = useAccountingStore();
  useEffect(() => {
    const fetchData = async () => {
      const adminsData = await appFetch("/api/users/list");
      if (adminsData && !adminsData.unauthorized) {
        setUsersList([...adminsData, { name: "Organization", user_id: -1 }]);
      }
    };
    fetchData();
  }, []);

  const setBuyDate = (value: Date) => {
    setExpense((prevExpense) => {
      return { ...prevExpense, recorded_date: value };
    });
  };

  const createExpense = async (type: string) => {
    // Vérifie si les champs obligatoires sont remplis

    if (
      !expense.product_name ||
      !expense.price ||
      !expense.recorded_date ||
      !expense.payment_method ||
      !expense.user ||
      !expense.file
    ) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return;
    }
    setLoading("createExpense", true);

    // Prépare les données pour la requête
    const formData = new FormData();
    formData.append("product_name", expense.product_name);
    formData.append("price", expense.price.toString());
    formData.append("recorded_date", expense.recorded_date.toISOString());
    formData.append("payment_method", expense.payment_method);
    formData.append("user_id", expense.user.user_id.toString());
    formData.append("expense_id", expense.expense_id.toString());
    if (expense.event?.event_id)
      formData.append("event_id", expense.event.event_id.toString());
    if (expense.file) formData.append("file", expense.file);
    try {
      const method = type === "addExpense" ? "POST" : "PUT";
      const response = await appFetch(`/api/accounting/${type}`, {
        method: method,
        body: formData,
      });
      if (response) {
        handleReset();
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Erreur lors de la création de la dépense :", error);
      setLoading("createExpense", false);
    }
    setLoading("createExpense", false);
  };

  useEffect(() => {
    const fetchQuoteDetails = async () => {
      if (expenseToEdit) {
        try {
          const expenseDetailData = await appFetch(
            `/api/accounting/getExpenseDetails?expense_id=${expenseToEdit.expense_id}`,
          );
          if (expenseDetailData && expenseDetailData.expense) {
            const fileUrl = `/api/accounting/getExpensesFile?expense_id=${expenseDetailData.expense.expense.expense_id}`;
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const file = new File(
              [blob],
              expenseDetailData.expense.expense.file_path,
              { type: blob.type },
            );

            console.log(expenseDetailData);
            setExpense((prevExpense) => ({
              ...prevExpense,
              ...expenseDetailData.expense.expense,
              recorded_date: new Date(
                expenseDetailData.expense.expense.recorded_date,
              ),
              product_name: expenseDetailData.expense.expense.product_name,
              price: expenseDetailData.expense.expense.price,
              // put organization if no user get
              user:
                expenseDetailData.expense.user.name === null
                  ? {
                      name: "Organization",
                      user_id: -1,
                    }
                  : expenseDetailData.expense.user,
              event: expenseDetailData.expense.event,
              payment_method: expenseDetailData.expense.expense.payment_method,
              file: file,
            }));
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails du devis :",
            error,
          );
        }
      }
    };

    fetchQuoteDetails();
  }, [expenseToEdit]);

  const handleReset = () => {
    setExpense({
      expense_id: -1,
      user_id: -1,
      product_name: "",
      price: 0,
      recorded_date: new Date(),
      payment_method: "",
      file_path: "",
      file: null,
    });
  };

  return (
    <>
      <div className="accountingSection">
        <StyledInputText
          label="Nom*"
          value={expense.product_name}
          setValue={(value: string) => {
            setExpense((prevExpense) => {
              return { ...prevExpense, product_name: value };
            });
          }}
        />
        <StyledInputNumber
          label="Prix*"
          value={expense.price.toString()}
          setValue={(value: string | null) => {
            setExpense((prevExpense) => {
              return { ...prevExpense, price: Number(value) };
            });
          }}
        />
        <StyledDropdown
          label="Payé par*"
          value={expense.user}
          setValue={(value: User | undefined) => {
            setExpense((prevExpense) => {
              return { ...prevExpense, user: value };
            });
          }}
          itemList={usersList}
          itemLabelKey="name"
          itemIdKey="user_id"
        />
        <StyledInputDate
          label="Date*"
          value={expense.recorded_date}
          setValue={setBuyDate}
        />
        <StyledDropdown
          label="Événement"
          value={expense.event}
          setValue={(value: EventType | undefined) => {
            setExpense((prevExpense) => {
              return { ...prevExpense, event: value };
            });
          }}
          itemList={partyList}
          itemLabelKey="name"
          itemIdKey="event_id"
        />

        <StyledDropdown
          label="Moyen de paiement*"
          value={{ payment_method: expense.payment_method }}
          setValue={(value: { payment_method: string } | undefined) => {
            setExpense((prevExpense) => {
              return {
                ...prevExpense,
                payment_method: value?.payment_method ?? "",
              };
            });
          }}
          itemList={[
            { payment_method: "Cash" },
            { payment_method: "Carte bancaire" },
            { payment_method: "Virement" },
          ]}
          itemLabelKey="payment_method"
          itemIdKey="payment_method"
          placeholder="Search..."
        />
        <StyledInputFile
          label="Justificatif*"
          setValue={(value: File | null) => {
            setExpense((prevExpense) => {
              return { ...prevExpense, file: value };
            });
          }}
          value={expense.file}
          className="accountingFileInput"
        />
      </div>
      <div className="accountingSection"></div>
      {!expenseToEdit && (
        <>
          <Button
            onClick={() => {
              createExpense("addExpense");
            }}
            className="submitBtn"
            label={`Ajouter la ${expenseType.id}`}
            isLoading={loadingStates.createExpense}
          />
          <Button
            onClick={() => {
              handleReset();
            }}
            className="squaredButton"
            icon={<FaUndo />}
          />
        </>
      )}
      {expenseToEdit && (
        <Button
          onClick={() => {
            createExpense("updateExpense");
          }}
          className="submitBtn"
          label={`Modifider la ${expenseType.id}`}
          isLoading={loadingStates.createExpense}
        />
      )}
    </>
  );
};
export default Expense;
