import "./EventRevenues.scss";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { fr } from "date-fns/locale/fr";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { registerLocale } from "react-datepicker";

import Spinner from "../../../../components/Utils/Spinner/Spinner";
import StyledDropdown from "../../../../components/Utils/StyledDropdown/StyledDropdown";
import useLoadingStore from "../../../../stores/LoadingStore";
import EventType from "../../../../types/Event";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";
registerLocale("fr", fr);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const EventRevenues = () => {
  const { loadingStates, setLoading } = useLoadingStore();
  const [expenseAmount, setExpenseAmount] = useState(0);
  const [revenueAmount, setRevenueAmount] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState<EventType | undefined>();
  const [eventsList, setEventsList] = useState<EventType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const partyData = await appFetch("/api/events/list");
      if (partyData && !partyData.unauthorized) {
        setEventsList(partyData);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!selectedEvent) {
      return;
    }
    const fetchData = async () => {
      setLoading("eventRevenues", true);

      const dashboardData = await appFetch(
        "/api/accounting/dashboard/eventRevenues",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            event_id: selectedEvent.event_id,
          }),
        },
      );
      if (dashboardData) {
        setExpenseAmount(dashboardData.expenseAmount);
        setRevenueAmount(dashboardData.revenueAmount);
      }
      setLoading("eventRevenues", false);
    };

    fetchData();
  }, [selectedEvent]);

  const chartData = {
    labels: ["Dépenses", "Revenus"],
    datasets: [
      {
        label: "Revenus vs Dépenses",
        data: [expenseAmount, revenueAmount],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)", // Couleur pour les dépenses
          "rgba(54, 162, 235, 0.5)", // Couleur pour les revenus
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)", // Couleur de bordure pour les dépenses
          "rgba(54, 162, 235, 1)", // Couleur de bordure pour les revenus
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="eventRevenues">
      <StyledDropdown
        label="Événement"
        value={selectedEvent}
        setValue={(selectedEvent: EventType | undefined) => {
          setSelectedEvent(selectedEvent);
        }}
        itemList={eventsList}
        itemLabelKey="name"
        itemIdKey="event_id"
        width="300px"
      />
      {loadingStates.eventRevenues ? <Spinner /> : <Bar data={chartData} />}
    </div>
  );
};

export default EventRevenues;
