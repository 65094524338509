import "./AccountingForm.scss";

import React, { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";

import Button from "../../../components/Utils/Button/Button";
import ButtonSwitcher from "../../../components/Utils/ButtonSwitcher/ButtonSwitcher";
import useAccountingStore from "../../../stores/AccountingStore";
import AccountingExpenseType from "../../../types/AccountingExpense";
import AccountingRevenueType from "../../../types/AccountingRevenue";
import EventType from "../../../types/Event";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import Expense from "./Expense/Expense";
import Revenue from "./Revenue/Revenue";

interface AccountingFormProps {
  expenseToEdit: AccountingExpenseType | null | AccountingRevenueType;
  setExpenseToEdit: React.Dispatch<
    React.SetStateAction<AccountingExpenseType | null | AccountingRevenueType>
  >;
}

const AccountingForm: React.FC<AccountingFormProps> = ({
  expenseToEdit,
  setExpenseToEdit,
}) => {
  // to get the user list for the user select
  const [partyList, setPartyList] = useState<EventType[]>([]);
  const { accountingType, setAccountingType } = useAccountingStore();

  useEffect(() => {
    const fetchData = async () => {
      const partyData = await appFetch("/api/events/list");
      if (partyData && !partyData.unauthorized) {
        setPartyList(partyData);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="accountingFormPage">
      {expenseToEdit ? (
        <Button
          onClick={() => setExpenseToEdit(null)}
          className="backBtn"
          icon={<FiArrowLeft />}
        />
      ) : (
        <ButtonSwitcher
          datas={[
            { id: "sortie", name: "Sortie" },
            { id: "rentrée", name: "Rentrée" },
          ]}
          setValue={setAccountingType}
          value={accountingType}
          className="accountingFormButtonSwitch"
        />
      )}
      {accountingType.id === "sortie" ? (
        <Expense
          expenseToEdit={expenseToEdit as AccountingExpenseType}
          expenseType={accountingType}
          partyList={partyList}
        />
      ) : (
        accountingType.id === "rentrée" && (
          <Revenue
            revenueToEdit={expenseToEdit as AccountingRevenueType}
            revenueType={accountingType}
            partyList={partyList}
          />
        )
      )}
    </div>
  );
};
export default AccountingForm;
