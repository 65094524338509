import "./ProductSelect.scss";

import React, { useEffect, useRef, useState } from "react";

import { ChevronDown, GrClose } from "../../../assets/svg/svgIcons";
import useDropdownStore from "../../../stores/DropdownStore";
import Service, { SelectedService } from "../../../types/Service";
import { StyledInputText } from "../StyledInput/StyledInput";

interface ServiceSelectProps {
  value: SelectedService;
  setValue: (value: SelectedService) => void;
  serviceList: Service[];
  disabled?: boolean;
  className?: string;
  addProductRow?: (service: Service) => void;
  selectedServices: SelectedService[];
  placeholder?: string;
}

const ServiceSelect: React.FC<ServiceSelectProps> = ({
  value,
  setValue,
  serviceList,
  disabled,
  className,
  addProductRow,
  selectedServices,
  placeholder,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { openDropdownId, setOpenDropdownId } = useDropdownStore();

  const isComponentVisible =
    openDropdownId === `service-${value.service_id}-${value.row_id}`;

  const handleClickItem = () => {
    if (isComponentVisible) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(`service-${value.service_id}-${value.row_id}`);
    }
  };

  // when click on toggle switch
  const setProduct = (service_id: number) => {
    const newProduct = serviceList.find((c) => c.service_id === service_id);
    if (newProduct) {
      setValue({ ...newProduct, quantity: 1 });
      addProductRow && addProductRow(newProduct);
    }
    setOpenDropdownId(null);
  };

  // when click outside dropdown
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    console.log(target.className);
    if (
      target &&
      typeof target.className === "string" &&
      ref.current &&
      !ref.current.contains(target) &&
      target.className !== "selectMenuName" &&
      !target.className.includes("productInput") &&
      !target.className.includes("selectName") &&
      !target.className.includes("placeholderName") &&
      !target.className.includes("productName")
    ) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    // when click outside dropdown, close dropdown
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const filteredServices = serviceList.filter(
    (service) =>
      service.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !selectedServices.some(
        (selected) => selected.service_id === service.service_id,
      ),
  );

  return (
    <div
      className={`productSelectDiv ${
        disabled ? "productSelectDivDisabled" : ""
      } ${className}`}
    >
      <div className="selectElement">
        <div
          className={`selectMenu ${
            isComponentVisible === true ? "active" : ""
          }`}
        >
          <div className="selectMenuName" onClick={() => handleClickItem()}>
            <div className="selectName">
              {value.name !== "" ? (
                <span className="productName">{value.name}</span>
              ) : (
                <span className="placeholderName">{placeholder}</span>
              )}
            </div>
            <ChevronDown className="arrowDown" />
          </div>
          <div className="submenu" ref={ref}>
            <div className="subselectItemTitle">
              <span>Choose service</span>
              <GrClose
                className="grClose"
                onClick={() => setOpenDropdownId(null)}
              />
            </div>
            <div className="submenuItemsContainer">
              <StyledInputText
                value={searchTerm}
                label=""
                className="productInput"
                setValue={(e: string) => setSearchTerm(e)}
                placeholder="Search..."
              />
              {filteredServices.map((item) => (
                <div
                  className="subselectItem"
                  key={item.service_id}
                  onClick={() => setProduct(item.service_id)}
                >
                  <span className="">{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSelect;
