import "./Footer.scss";

import React from "react";
import { Link } from "react-router-dom";

import { Logo } from "../../assets/svg/svgIcons";

const Footer: React.FC = () => {
  return (
    <footer className="footerPage">
      <div className="footerLogo">
        <Link to="/">
          <Logo className="logo" />
        </Link>
        <div>
          Powered by
          <a href="https://falcohmsystem.com/" target="_blank" rel="noreferrer">
            <b> Falc&apos;Ohm System ASBL</b>
          </a>
        </div>
      </div>
      <div className="footerLinks">
        <div className="termsOfUse">
          En utilisant ce site, vous acceptez nos{" "}
          <Link to="/terms-of-uses">
            <span>Termes d&apos;utilisation</span>
          </Link>{" "}
          et{" "}
          <Link to="/privacy">
            <span>Politique de confidentialité</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
