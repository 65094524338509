import "./CatalogueList.scss";

import React, { useEffect, useState } from "react";

import Spinner from "../../../components/Utils/Spinner/Spinner";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import CategoryType from "../../../types/Category";
import ProductType from "../../../types/Product";
import ServiceType from "../../../types/Service";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import ProductCard from "./ProductCard/ProductCard";
import ServiceCard from "./ServiceCard/ServiceCard";

interface CatalogueListProps {
  type: string;
}

export type CatalogueItemType = ProductType | ServiceType;

const isProductType = (item: CatalogueItemType): item is ProductType => {
  return (item as ProductType).product_id !== undefined;
};

const CatalogueList: React.FC<CatalogueListProps> = ({ type }) => {
  const [catalogue, setCatalogue] = useState<CatalogueItemType[]>([]);
  const [categories, setCategories] = useState<CategoryType[]>();

  const [search, setSearch] = useState("");
  const { setLoading, loadingStates } = useLoadingStore();

  useEffect(() => {
    const fetchData = async () => {
      setLoading("getCatalogue", true);
      const apiCall = type === "Produits" ? "products" : "services";
      const data = await appFetch(`/api/${apiCall}/list`);
      if (data) {
        const emptyItem =
          type === "Produits"
            ? {
                category_id: "",
                created_at: "",
                image: "",
                name: "",
                price: "",
                product_id: -1, // id fictif
                stock: 0,
                subCategory_id: null,
                updated_at: "",
              }
            : {
                service_id: -1, // id fictif
                name: "",
                description: "",
                price: "",
                duration: "",
                created_at: "",
                updated_at: "",
              };

        // Ajout de l'élément vide au début de la liste
        setCatalogue([emptyItem, ...data]);
        setSearch("");
      }
      const categoryData = await appFetch(`/api/category/getCategoryList`);
      if (categoryData) {
        console.log(categoryData);
        setCategories(categoryData.categorys);
      }
      setLoading("getCatalogue", false);
    };

    fetchData();
  }, [type]);

  let finalCatalogue: CatalogueItemType[] = [];
  if (catalogue.length > 0) {
    const emptyItem = catalogue[0];
    const itemsToFilter = catalogue.slice(1); // Exclure l'élément vide du filtrage

    const filteredCatalogue = itemsToFilter.filter((item) =>
      item.name?.toLowerCase().includes(search.toLowerCase()),
    );

    finalCatalogue = [emptyItem, ...filteredCatalogue]; // Combinez l'élément vide avec les éléments filtrés
  }

  return (
    <div className="catalogueListPage">
      <StyledInputText
        label="Rechercher"
        value={search}
        setValue={setSearch}
        className="searchInput"
      />
      {loadingStates.getCatalogue ? (
        <Spinner />
      ) : (
        <div className="catalogueList">
          {finalCatalogue.map((item) => {
            if (isProductType(item)) {
              // Rendu pour les produits
              return (
                <ProductCard
                  product={item}
                  key={item.product_id}
                  setCatalogue={setCatalogue}
                  categories={categories || []}
                />
              );
            } else {
              // Rendu pour les services
              return (
                <ServiceCard
                  service={item}
                  key={item.service_id}
                  setCatalogue={setCatalogue}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default CatalogueList;
