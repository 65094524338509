import "./Plan.scss";

import PlanCard from "../../StripePayment/PlanCard/PlanCard";

const Plan = () => {
  return (
    <div className="landingPlan">
      <h2 className="landingPlanTitle">Découvrez notre Abonnement Premium</h2>

      <PlanCard mode="modify" />
    </div>
  );
};

export default Plan;
