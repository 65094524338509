import "./TermsOfUses.scss";

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Button from "../../components/Utils/Button/Button";

const TermsOfUses: React.FC = () => {
  const ACCEPTABLE_USE_LIST = [
    "Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users to send unsolicited email or under false pretenses",
    "Systematically retrieve data or other content from the Site to compile a database or directory without written permission from us",
    "Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any content or enforce limitations on the use",
    "Use any information obtained from the Site in order to harass, abuse, or harm another person",
    "Use the Site or our content as part of any effort to compete with us or to create a revenue-generating endeavor or commercial enterprise",
    "Harass, annoy, intimidate, or threaten any of our employees, agents, or other users",
    "Delete the copyright or other proprietary rights notice from any of the content",
    "Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site",
    "Upload or transmit (or attempt to upload or transmit) viruses, Trojan horses, or other material that interferes with any party's uninterrupted use and enjoyment of the Site, or any material that acts as a passive or active information collection or transmission mechanism",
    "Use, launch, or engage in any automated use of the system, such as using scripts to send comments or messages, robots, scrapers, offline readers, or similar data gathering and extraction tools",
    "Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site",
    "Use the Site in a manner inconsistent with any applicable laws or regulations",
    "Advertise products or services not intended by us",
    "Trick, defraud, or mislead us and other users",
    "Make improper use of our support services, or submit false reports of abuse or misconduct",
    "Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools",
    "Interfere with, disrupt, or create an undue burden on the Site or the networks and services connected to the Site",
    "Attempt to impersonate another user or person, or use the username of another user",
    "Use the Site to advertise or sell goods and services",
    "Falsely imply a relationship with us or another company with whom you do not have a relationship",
  ];
  const navigate = useNavigate();
  return (
    <div className="loginRoot">
      <div className="terms">
        <Button
          icon={<FaArrowLeft />}
          onClick={() => navigate("/")}
          style="square"
        />
        <h1 className="title">Terms of Service</h1>
        <p className="lastUpdated">Last updated March 05, 2024</p>
        <div className="content">
          <div className="chapter">
            <h2 className="chapterTitle">1. Agreement to Terms</h2>
            <p className="chapterText">
              These Terms of Service constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (
              <b>you</b>), and <b>Falc&apos;Ohm System ASBL</b>, located at{" "}
              <b>Rue des croix du feu 4, 1473 Glabais Belgium (we, us)</b>,
              concerning your access to and use of the nestohm.com website as
              well as any related applications (the <b>Site</b>).
            </p>
            <p className="chapterText">
              The Site provides the following services:{" "}
              <b>Level 1 ASBL Infrastructure (Services)</b>. You agree that by
              accessing the Site and/or Services, you have read, understood, and
              agree to be bound by all of these Terms of Service.
            </p>
            <p className="chapterText">
              <b>
                If you do not agree with all of these Terms of Service, then you
                are prohibited from using the Site and Services and you must
                discontinue use immediately.
              </b>
            </p>
            <p className="chapterText">
              We may make changes to these Terms of Service at any time. The
              updated version of these Terms of Service will be indicated by an
              updated &quot;Revised&quot; date and the updated version will be
              effective as soon as it is accessible. You are responsible for
              reviewing these Terms of Service to stay informed of updates. Your
              continued use of the Site represents that you have accepted such
              changes.
            </p>
            <p className="chapterText">
              We may update or change the Site from time to time to reflect
              changes to our products, our &quot;users&quot; needs and/or our
              business priorities.
            </p>
            <p className="chapterText">
              The Site is intended for users who are at least 18 years old. If
              you are under the age of 18, you are not permitted to register for
              the Site or use the Services without parental permission.
            </p>
            <p className="chapterText">
              Additional policies which also apply to your use of the Site
              include our Privacy Policy which sets out the terms on which we
              process any personal data we collect from you, or that you provide
              to us. By using the Site, you consent to such processing and you
              warrant that all data provided by you is accurate.
            </p>
          </div>
          <div className="chapter">
            <h2 className="chapterTitle">2. Acceptable Use</h2>
            <p className="chapterText">
              You may not access or use the Site for any purpose other than that
              for which we make the site and our services available. The Site
              may not be used in connection with any commercial endeavors except
              those that are specifically endorsed or approved by us.
            </p>
            <p className={`$"chapterText"`}>
              As a user of this Site, you agree not to:
            </p>
            <ul className="list">
              {ACCEPTABLE_USE_LIST.map((item, index) => (
                <li key={index} className={`list__item _white _terms`}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="chapter">
            <h2 className="chapterTitle">3. Our content</h2>
            <p className="chapterText">
              Unless otherwise indicated, the Site and Services including source
              code, databases, functionality, software, website designs, audio,
              video, text, photographs, and graphics on the Site (
              <b>Our Content</b>) are owned or licensed to us, and are protected
              by copyright and trade mark laws.
            </p>
            <p className="chapterText">
              Except as expressly provided in these Terms of Service, no part of
              the Site, Services or Our Content may be copied, reproduced,
              aggregated, republished, uploaded, posted, publicly displayed,
              encoded, translated, transmitted, distributed, sold, licensed, or
              otherwise exploited for any commercial purpose whatsoever, without
              our express prior written permission.
            </p>
            <p className="chapterText">
              Provided that you are eligible to use the Site, you are granted a
              limited licence to access and use the Site and Our Content and to
              download or print a copy of any portion of the Content to which
              you have properly gained access solely for your personal,
              non-commercial use.
            </p>
            <p className="chapterText">
              You shall not (a) try to gain unauthorized access to the Site or
              any networks, servers or computer systems connected to the Site;
              and/or (b) make for any purpose including error correction, any
              modifications, adaptions, additions or enhancements to the Site or
              Our Content, including the modification of the paper or digital
              copies you may have downloaded.
            </p>
            <p className="chapterText">
              We shall (a) prepare the Site and Our Content with reasonable
              skill and care; and (b) use industry standard virus detection
              software to try to block the uploading of content to the Site that
              contains viruses.
            </p>
            <p className="chapterText">
              The content on the Site is provided for general information only.
              It is not intended to amount to advice on which you should rely.
              You must obtain professional or specialist advice before taking,
              or refraining from taking, any action on the basis of the content
              on the Site.
            </p>
            <p className="chapterText">
              Although we make reasonable efforts to update the information on
              our site, we make no representations, warranties or guarantees,
              whether express or implied, that Our Content on the Site is
              accurate, complete or up to date.
            </p>
          </div>
          <div className="chapter">
            <h2 className="chapterTitle">4. Site Management</h2>
            <p className="chapterText">
              We reserve the right at our sole discretion, to (1) monitor the
              Site for breaches of these Terms of Service; (2) take appropriate
              legal action against anyone in breach of applicable laws or these
              Terms of Service; (3) remove from the Site or otherwise disable
              all files and content that are excessive in size or are in any way
              a burden to our systems; and (4) otherwise manage the Site in a
              manner designed to protect our rights and property and to
              facilitate the proper functioning of the Site and Services.
            </p>
            <p className="chapterText">
              We do not guarantee that the Site will be secure or free from bugs
              or viruses.
            </p>
            <p className="chapterText">
              You are responsible for configuring your information technology,
              computer programs and platform to access the Site and you should
              use your own virus protection software.
            </p>
          </div>
          <div className="chapter">
            <h2 className="chapterTitle">
              5. Modifications to and availability of the Site
            </h2>
            <p className="chapterText">
              We reserve the right to change, modify, or remove the contents of
              the Site at any time or for any reason at our sole discretion
              without notice. We also reserve the right to modify or discontinue
              all or part of the Services without notice at any time.
            </p>
            <p className="chapterText">
              We cannot guarantee the Site and Services will be available at all
              times. We may experience hardware, software, or other problems or
              need to perform maintenance related to the Site, resulting in
              interruptions, delays, or errors. You agree that we have no
              liability whatsoever for any loss, damage, or inconvenience caused
              by your inability to access or use the Site or Services during any
              downtime or discontinuance of the Site or Services. We are not
              obliged to maintain and support the Site or Services or to supply
              any corrections, updates, or releases.
            </p>
            <p className="chapterText">
              There may be information on the Site that contains typographical
              errors, inaccuracies, or omissions that may relate to the
              Services, including descriptions, pricing, availability, and
              various other information. We reserve the right to correct any
              errors, inaccuracies, or omissions and to change or update the
              information at any time, without prior notice.
            </p>
          </div>
          <div className="chapter">
            <h2 className="chapterTitle">
              6. Disclaimer/Limitation of Liability
            </h2>
            <p className="chapterText">
              The Site and Services are provided on an as-is and as-available
              basis. You agree that your use of the Site and/or Services will be
              at your sole risk except as expressly set out in these Terms of
              Service. All warranties, terms, conditions and undertakings,
              express or implied (including by statute, custom or usage, a
              course of dealing, or common law) in connection with the Site and
              Services and your use thereof including, without limitation, the
              implied warranties of satisfactory quality, fitness for a
              particular purpose and non-infringement are excluded to the
              fullest extent permitted by applicable law.
            </p>
            <p className="chapterText">
              We make no warranties or representations about the accuracy or
              completeness of the Site’s content and are not liable for any (1)
              errors or omissions in content; (2) any unauthorized access to or
              use of our servers and/or any and all personal information and/or
              financial information stored on our server; (3) any interruption
              or cessation of transmission to or from the site or services;
              and/or (4) any bugs, viruses, trojan horses, or the like which may
              be transmitted to or through the site by any third party. We will
              not be responsible for any delay or failure to comply with our
              obligations under these Terms of Service if such delay or failure
              is caused by an event beyond our reasonable control.
            </p>
            <p className="chapterText">
              Our responsibility for loss or damage suffered by you:
            </p>
            <p className="chapterText">
              <b>Whether you are a consumer or a business user:</b>
            </p>
            <ul className="list">
              <li className={`list__item _white _terms`}>
                We do not exclude or limit in any way our liability to you where
                it would be unlawful to do so. This includes liability for death
                or personal injury caused by our negligence or the negligence of
                our employees, agents or subcontractors and for fraud or
                fraudulent misrepresentation.
              </li>
              <li className={`list__item _white _terms`}>
                If we fail to comply with these Terms of Service, we will be
                responsible for loss or damage you suffer that is a foreseeable
                result of our breach of these Terms of Service, but we would not
                be responsible for any loss or damage that were not foreseeable
                at the time you started using the Site/Services.
              </li>
            </ul>
            <p className="chapterText">
              Notwithstanding anything to the contrary contained in the
              Disclaimer/Limitation of Liability section, our liability to you
              for any cause whatsoever and regardless of the form of the action,
              will at all times be limited to a total aggregate amount equal to
              the greater of (a) the sum of CHF 1000 or (b) the amount paid, if
              any, by you to us for the Services/Site during the six (6) month
              period prior to any cause of action arising. Different limitations
              and exclusions of liability will apply to liability arising as a
              result of the supply of any products to you, which will be set out
              in our Terms of Service.
            </p>
            <p className="chapterText">
              <b>If you are a business user:</b>
            </p>
            <p className="chapterText">
              We will not be liable to you for any loss or damage, whether in
              contract, tort (including negligence), breach of statutory duty,
              or otherwise, even if foreseeable, arising under or in connection
              with:
            </p>
            <ul className="list">
              <li className={`list__item _white _terms`}>
                use of, or inability to use, our Site/Services; or
              </li>
              <li className={`list__item _white _terms`}>
                use of or reliance on any content displayed on our Site.
              </li>
            </ul>
            <p className="chapterText">
              In particular, we will not be liable for:
            </p>
            <ul className="list">
              <li className={`list__item _white _terms`}>
                loss of profits, sales, business, or revenue;
              </li>
              <li className={`list__item _white _terms`}>
                business interruption;
              </li>
              <li className={`list__item _white _terms`}>
                loss of anticipated savings;
              </li>
              <li className={`list__item _white _terms`}>
                loss of business opportunity, goodwill or reputation; or
              </li>
              <li className={`list__item _white _terms`}>
                any indirect or consequential loss or damage.
              </li>
            </ul>
            <p className="chapterText">
              <b>If you are a consumer user:</b>
            </p>
            <ul className="list">
              <li className={`list__item _white _terms`}>
                Please note that we only provide our Site for domestic and
                private use. You agree not to use our Site for any commercial or
                business purposes, and we have no liability to you for any loss
                of profit, loss of business, business interruption, or loss of
                business opportunity.
              </li>
              <li className={`list__item _white _terms`}>
                If defective digital content that we have supplied, damages a
                device or digital content belonging to you and this is caused by
                our failure to use reasonable care and skill, we will either
                repair the damage or pay you compensation.
              </li>
              <li className={`list__item _white _terms`}>
                You have legal rights in relation to goods that are faulty or
                not as described. Nothing in these Terms of Service will affect
                these legal rights.
              </li>
            </ul>
          </div>
          <div className="chapter">
            <h2 className="chapterTitle">8. General</h2>
            <p className="chapterText">
              These Terms of Service shall remain in full force and effect while
              you use the Site or Services or are otherwise a user of the Site,
              as applicable. You may terminate your use or participation at any
              time, for any reason by contacting us at falcohm6tm@outlook.com.
            </p>
            <p className="chapterText">
              Without limiting any other provision of these Terms of Service, we
              reserve the right to, in our sole discretion and without notice or
              liability, deny access to and use of the Site and the Services
              (including blocking certain IP addresses), to any person for any
              reason including without limitation for breach of any
              representation, warranty or covenant contained in these Terms of
              Service or of any applicable law or regulation.
            </p>
            <p className="chapterText">
              If we determine, in our sole discretion, that your use of the
              Site/Services is in breach of these Terms of Service or of any
              applicable law or regulation, we may terminate your use or
              participation in the Site and the Services or delete any content
              or information that you posted at any time, without warning, in
              our sole discretion.
            </p>
          </div>
          <div className="chapter">
            <h2 className="chapterTitle">7. Term and Termination</h2>
            <p className="chapterText">
              Visiting the Site, sending us emails, and completing online forms
              constitute electronic communications. You consent to receive
              electronic communications and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communication be in writing.
            </p>
            <p className="chapterText">
              <b>
                You hereby agree to the use of electronic signatures, contracts,
                orders and other records and to electronic delivery of notices,
                policies and records of transactions initiated or completed by
                us or via the Site.
              </b>{" "}
              You hereby waive any rights or requirements under any statutes,
              regulations, rules, ordinances or other laws in any jurisdiction
              which require an original signature or delivery or retention of
              non-electronic records, or to payments or the granting of credits
              by other than electronic means.
            </p>
            <p className="chapterText">
              These Terms of Service and any policies or operating rules posted
              by us on the Site or in respect to the Services constitute the
              entire agreement and understanding between you and us.
            </p>
            <p className="chapterText">
              Our failure to exercise or enforce any right or provision of these
              Terms of Service shall not operate as a waiver of such right or
              provision. We may assign any or all of our rights and obligations
              to others at any time.
            </p>
            <p className="chapterText">
              We may assign any or all of our rights and obligations to others
              at any time.
            </p>
            <p className="chapterText">
              We shall not be responsible or liable for any loss, damage, delay
              or failure to act caused by any cause beyond our reasonable
              control.
            </p>
            <p className="chapterText">
              If any provision or part of a provision of these Terms of Service
              is unlawful, void or unenforceable, that provision or part of the
              provision is deemed severable from these Terms of Service and does
              not affect the validity and enforceability of any remaining
              provisions.
            </p>
            <p className="chapterText">
              There is no joint venture, partnership, employment or agency
              relationship created between you and us as a result of these Terms
              of Service or use of the Site or Services.
            </p>
            <p className="chapterText">
              <b>For consumers only</b> -{" "}
              <i>
                Please note that these Terms of Service, their subject matter
                and their formation, are governed by Belgium law. You and we
                both agree that the courts of Belgium will have exclusive
                jurisdiction. If you have any complaint or wish to raise a
                dispute under these Terms of Service or otherwise in relation to
                the Site please contact us via falcohm6tm@outlook.com.
              </i>
            </p>
            <p className="chapterText">
              <b>For business users only</b> -{" "}
              <i>
                If you are a business user, these Terms of Service, their
                subject matter and their formation (and any non-contractual
                disputes or claims) are governed by Belgium Law. We both agree
                to the exclusive jurisdiction of the courts of Belgium.
              </i>
            </p>
            <p className="chapterText">
              In order to resolve a complaint regarding the Services or to
              receive further information regarding use of the Services, please
              contact us by email at falcohm6tm@outlook.com.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUses;
