import "./ButtonSwitcher.scss";

import React, { useCallback } from "react";

type AcceptableTypes = ButtonSwitcherValue;

interface ButtonSwitcherProps {
  value: AcceptableTypes;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  datas: AcceptableTypes[];
  className?: string;
  localStorageKey?: string;
  rows?: number;
  collumns?: number;
  width?: string;
}

export interface ButtonSwitcherValue {
  id: string;
  name: string;
}

const ButtonSwitcher: React.FC<ButtonSwitcherProps> = ({
  value,
  setValue,
  datas,
  className,
  localStorageKey,
  rows,
  collumns,
  width = "fit-content",
}) => {
  const handleClick = useCallback(
    (item: ButtonSwitcherValue) => {
      setValue(item);
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, item.id);
      }
    },
    [setValue],
  );

  return (
    <div
      className={`buttonSwitcher ${className}`}
      style={{
        gridTemplateColumns: `repeat(${collumns}, 1fr)`,
        gridTemplateRows: `repeat(${rows}, 1fr)`,
        width: width,
      }}
    >
      {datas.map((item) => (
        <button
          key={item.id}
          onClick={() => handleClick(item)}
          className={`buttonSetValue ${value.id === item.id ? "selected" : ""}`}
        >
          {item.name}
        </button>
      ))}
    </div>
  );
};

export default ButtonSwitcher;
