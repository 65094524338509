import "./PrivateRoutes.scss";

import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import Spinner from "../../../components/Utils/Spinner/Spinner";
import useVerifyToken from "../../../hooks/useVerifyToken";
import useUserStore from "../../../stores/UserStore";

const PrivateRoutes = () => {
  const { isAuthenticated } = useUserStore();

  const { verifyToken, isLoading } = useVerifyToken();

  useEffect(() => {
    verifyToken();
  }, []);

  if (isLoading) {
    return (
      <div className="walletConnectPromptDiv">
        <Spinner />
      </div>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
