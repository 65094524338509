import "./StripePayment.scss";

import toast from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Utils/Button/Button";
import appFetch from "../../Utils/Services/Fetch/appFetch";
import PlanCard from "./PlanCard/PlanCard";

const StripePayment = () => {
  const { mode } = useParams();
  const navigate = useNavigate();

  const cancelSubscription = async () => {
    try {
      const response = await appFetch("/api/stripe/cancelSubscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response) {
        toast.success("Abonnement annulé avec succès");
        navigate("/payment/create");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBack = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/");
  };

  return (
    <div className="stripeRoot">
      <div className="organizationSetupPage">
        <div className="stripeHeader">
          <Button
            className="backButton"
            icon={<FaArrowLeft />}
            onClick={() => handleBack()}
          />
          <h1>Abonnements</h1>
        </div>

        <PlanCard mode={mode} />
        {mode === "modify" && (
          <Button
            label="Annuler l'abonnement"
            className="cancelSubscriptionButton"
            onClick={cancelSubscription}
          />
        )}
      </div>
    </div>
  );
};

export default StripePayment;
