import "./CreateOrganization.scss";

import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/Utils/Button/Button";
import {
  StyledInputEmail,
  StyledInputPhone,
  StyledInputText,
} from "../../../components/Utils/StyledInput/StyledInput";
import StyledSwitch from "../../../components/Utils/StyledSwitch/StyledSwitch";
import OrganizationType from "../../../types/Organization";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const CreateOrganization: React.FC = () => {
  const [organisation, setOrganisation] = useState<OrganizationType>({
    organization_id: 0,
    organization_name: "",
    address: "",
    phone: "",
    email: "",
    vat_number: undefined,
    bank_account: "",
    is_vat_subject: true,
  });
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(organisation);

    try {
      const response = await appFetch("/api/organization/addOrganization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(organisation),
      });
      console.log(response);

      if (response) {
        // redirect to home page
        navigate("/payment/create");
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="createOrganization">
      <form onSubmit={handleSubmit}>
        <StyledInputText
          label="Nom*"
          value={organisation.organization_name ?? ""}
          setValue={(value: string) =>
            setOrganisation({ ...organisation, organization_name: value })
          }
          required={true}
          className="inputLogin"
        />
        <StyledInputText
          label="Adresse*"
          value={organisation.address ?? ""}
          setValue={(value: string) =>
            setOrganisation({ ...organisation, address: value })
          }
          required={true}
          className="inputLogin"
        />

        <StyledInputPhone
          label="Téléphone*"
          value={organisation.phone ?? ""}
          setValue={(value: string) =>
            setOrganisation({ ...organisation, phone: value })
          }
          required={true}
          className="inputLogin"
        />

        <StyledInputEmail
          label="Email*"
          value={organisation.email ?? ""}
          setValue={(value: string) =>
            setOrganisation({ ...organisation, email: value })
          }
          required={true}
          className="inputLogin"
        />

        <StyledInputText
          label="Compte bancaire*"
          value={organisation.bank_account ?? ""}
          setValue={(value: string) =>
            setOrganisation({ ...organisation, bank_account: value })
          }
          required={true}
          className="inputLogin"
        />

        <StyledSwitch
          checked={organisation.is_vat_subject ?? false}
          onChange={() =>
            setOrganisation({
              ...organisation,
              is_vat_subject: !organisation.is_vat_subject,
            })
          }
          label="Assujettissement à la TVA*"
        />
        {organisation.is_vat_subject && (
          <StyledInputText
            label="Numéro de TVA"
            value={organisation.vat_number ?? ""}
            setValue={(value: string) =>
              setOrganisation({ ...organisation, vat_number: value })
            }
            required={true}
            className="inputLogin"
          />
        )}

        <Button type="submit" className="buttonLogin" label="Créer" />
      </form>
    </div>
  );
};
export default CreateOrganization;
