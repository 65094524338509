import "./PlanCard.scss";

import { useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/Utils/Button/Button";
import PlanType from "../../../types/Plan";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

interface PlanCardProps {
  mode: string | undefined;
}

type CaracteristiquesKeys =
  | "utilisateurs"
  | "clients"
  | "factures"
  | "devis"
  | "nombre_produits"
  | "nombre_services"
  | "sauvegarde_cloud"
  | "creation_factures_pdf";

type CaracteristiquesNomsType = {
  [key in CaracteristiquesKeys]: string;
};
const PlanCard = ({ mode }: PlanCardProps) => {
  const caracteristiquesNoms: CaracteristiquesNomsType = {
    utilisateurs: "Utilisateurs",
    clients: "Clients",
    factures: "Factures",
    devis: "Devis",
    nombre_produits: "Produits",
    nombre_services: "Services",
    sauvegarde_cloud: "Sauvegarde Cloud",
    creation_factures_pdf: "Génération de PDF",
  };
  const stripe = useStripe();
  const navigate = useNavigate();
  const [plan, setPlan] = useState<PlanType[]>();

  useEffect(() => {
    const getPlans = async () => {
      try {
        const response = await appFetch("/api/stripe/getPlans", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response) {
          setPlan(response);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Une erreur est survenue");
      }
    };

    getPlans();
  }, []);

  // Fonction pour afficher l'icône appropriée
  const renderIcon = (available: boolean) => {
    return available ? (
      <FaCheck className="iconAvailable" />
    ) : (
      <FaTimes className="iconNotAvailable" />
    );
  };

  const createSubscription = async (plan: PlanType) => {
    try {
      const response = await appFetch(
        `/api/stripe/${
          mode === "modify" ? "modifySubscription" : "createCheckoutSession"
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ priceId: plan.price_id }),
        },
      );

      const sessionData = await response;
      if (!sessionData) {
        return;
      }
      if (!stripe) {
        console.error("Stripe n'est pas initialisé");
        return;
      }

      if (mode === "create") {
        // Redirection vers la page de paiement Stripe
        const result = await stripe?.redirectToCheckout({
          sessionId: sessionData.id,
        });
        console.log(result, stripe);

        if (result) {
          toast.success("Redirection vers la page de paiement...");
        }
      }
      if (mode === "modify") {
        if (sessionData.success) {
          toast.success("Abonnement modifié avec succès");
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="planWrapper">
      {plan &&
        plan.map((plan) => (
          <div className="planCard" key={plan.plan_id}>
            <div className="planHeader">
              <h2>{plan.nom}</h2>
              <p className="planPrice">
                <span>{plan.prix}</span>
                <span>€ /mois</span>
              </p>
            </div>
            <div className="features">
              {Object.entries(plan).map(([key, value]) => {
                if (key in caracteristiquesNoms) {
                  const displayName =
                    caracteristiquesNoms[key as keyof CaracteristiquesNomsType];
                  return (
                    <p key={key}>
                      {renderIcon(typeof value === "boolean" ? value : !!value)}
                      {typeof value === "boolean"
                        ? displayName
                        : isNaN(Number(value))
                          ? `${displayName} ${value}`
                          : `${value} ${displayName}`}
                    </p>
                  );
                }
                return null;
              })}
            </div>
            {mode !== "modify" && (
              <Button
                onClick={() => createSubscription(plan)}
                className="buttonLogin"
                label="S'abonner"
              />
            )}
          </div>
        ))}
    </div>
  );
};
export default PlanCard;
