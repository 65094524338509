import "./FAQ.scss";

import { useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const questionsAndAnswers = [
  {
    question: "Comment puis-je m'inscrire ?",
    answer:
      "Vous pouvez vous inscrire en cliquant sur le bouton 'Inscription' en haut de la page.",
  },
  {
    question: "Puis-je annuler mon abonnement à tout moment ?",
    answer:
      "Oui, vous pouvez annuler votre abonnement à tout moment depuis les paramètres de votre compte.",
  },
  {
    question: "Offrez-vous des remboursements ?",
    answer:
      "Nous offrons des remboursements dans les cas spécifiés dans notre politique de remboursement.",
  },
  {
    question:
      "Comment puis-je ajouter de nouveaux clients à ma base de données ?",
    answer:
      "Vous pouvez ajouter de nouveaux clients directement depuis le tableau de bord de gestion des clients, en utilisant le bouton 'Ajouter un client'. Remplissez les informations requises dans le formulaire qui apparaît pour enregistrer un nouveau client.",
  },
  {
    question: "Comment générer un devis pour un client ?",
    answer:
      "Pour générer un devis, rendez-vous dans la section 'Devis', sélectionnez le client concerné et ajoutez les produits ou services désirés. Vous pouvez ensuite personnaliser le devis selon vos besoins avant de l'envoyer directement au client.",
  },
  {
    question: "Puis-je personnaliser les modèles de devis et de factures ?",
    answer:
      "Absolument ! Notre plateforme vous permet de personnaliser les logos des devis et des factures. Accédez à la section de personnalisation depuis vos paramètres pour adapter ces documents à l'image de votre entreprise.",
  },
  {
    question: "Comment puis-je suivre les entrées et sorties d'argent ?",
    answer:
      "Notre outil de gestion comptable vous permet de suivre en temps réel toutes les transactions financières. Consultez le tableau de bord de la comptabilité pour un aperçu complet des entrées et sorties d'argent.",
  },
  {
    question:
      "Est-il possible d'ajouter des utilisateurs supplémentaires à mon compte ?",
    answer:
      "Oui, vous pouvez ajouter des utilisateurs supplémentaires. Rendez-vous dans la section 'Organisation' pour gérer les accès.",
  },
];

const FAQ = () => {
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<
    number | null
  >(null);
  // Correction ici : Spécifiez le type pour useRef
  const answerRefs = useRef<(HTMLDivElement | null)[]>([]);

  const toggleAnswer = (index: number) => {
    const answer = answerRefs.current[index];
    const isOpening = selectedQuestionIndex !== index;

    if (answer) {
      if (isOpening) {
        answer.style.maxHeight = answer.scrollHeight + "px";
      } else {
        answer.style.maxHeight = "0";
      }
    }

    setSelectedQuestionIndex(isOpening ? index : null);
  };

  return (
    <div className="faq">
      <h1>FAQ</h1>
      {questionsAndAnswers.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAnswer(index)}>
            <h2>{item.question}</h2>
            {selectedQuestionIndex === index ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </div>
          <div
            ref={(el) => (answerRefs.current[index] = el)}
            className="faq-answer"
            style={{
              maxHeight: "0",
              overflow: "hidden",
              transition: "max-height 0.5s ease",
              padding: "0 1rem",
            }}
          >
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
