import "./Sale.scss";

import React, { useEffect } from "react";

import HeaderButtons from "../../components/Utils/HeaderButtons/HeaderButtons";
import useSaleStore from "../../stores/SaleStore";
import SaleType from "../../types/Sale";
import SaleForm from "./SaleForm/SaleForm";
import SaleList from "./SaleList/SaleList";

const Sale: React.FC = () => {
  const { saleActiveButton, setSaleActiveButton, saleToEdit, setSaleToEdit } =
    useSaleStore();

  const handleEditSale = (sale: SaleType) => {
    console.log(sale);
    setSaleToEdit(sale);
    setSaleActiveButton("Modifier");
  };

  useEffect(() => {
    if (saleActiveButton === "Créer" || saleActiveButton === "Liste") {
      setSaleToEdit(null);
    }
  }, [saleActiveButton]);

  const buttons = [
    { id: "Créer", render: "Créer" },
    { id: "Modifier", render: "Modifier" },
    { id: "Liste", render: "Liste" },
  ];

  return (
    <div className="root">
      <HeaderButtons
        activeButton={saleActiveButton}
        setActiveButton={setSaleActiveButton}
        buttons={buttons}
      />
      <div className="salePage">
        {saleActiveButton === "Créer" && <SaleForm saleToEdit={null} />}
        {saleActiveButton === "Modifier" &&
          (saleToEdit ? (
            <SaleForm saleToEdit={saleToEdit} />
          ) : (
            <SaleList onEdit={handleEditSale} onEditPage={true} />
          ))}

        {saleActiveButton === "Liste" && <SaleList onEdit={handleEditSale} />}
      </div>
    </div>
  );
};
export default Sale;
