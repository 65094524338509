import "./QuoteSettings.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Button from "../../../components/Utils/Button/Button";
import Spinner from "../../../components/Utils/Spinner/Spinner";
import { StyledTextArea } from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const QuoteSettings = () => {
  const [terms, setTerms] = useState("");
  const { loadingStates, setLoading } = useLoadingStore();

  const fetchData = async () => {
    setLoading("getOrganization", true);
    const organizationResponse = await appFetch(
      "/api/organization/getOrganization",
    );

    if (organizationResponse) {
      setTerms(organizationResponse.organization.terms);
    }
    setLoading("getOrganization", false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateTerms = async () => {
    const data = await appFetch("/api/organization/updateOrganizationTerms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        terms: terms,
      }),
    });

    if (data) {
      toast.success(data.message);
    }
  };

  return (
    <div className={`quoteSettingsPage `}>
      {loadingStates.getOrganization ? (
        <Spinner />
      ) : (
        <div className="quoteSettings">
          <StyledTextArea
            label="Conditions générales"
            value={terms}
            setValue={setTerms}
          />
          <Button onClick={handleUpdateTerms} label="Enregistrer" />
        </div>
      )}
    </div>
  );
};

export default QuoteSettings;
