import { create } from "zustand";

interface UserState {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  isVatSubject: boolean;
  setIsVatSubject: (isVatSubject: boolean) => void;
}

const useUserStore = create<UserState>((set) => ({
  isAuthenticated: false,
  setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),

  isVatSubject: false,
  setIsVatSubject: (isVatSubject) => set({ isVatSubject }),
}));

export default useUserStore;
