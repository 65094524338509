import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useUserStore from "../stores/UserStore";
import appFetch from "../Utils/Services/Fetch/appFetch";

const useVerifyToken = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAuthenticated } = useUserStore();

  const verifyToken = async () => {
    console.log("verifyToken", location);
    setIsLoading(true);
    try {
      const response = await appFetch(
        "/api/auth/verifyToken",
        {},
        location.pathname === "/" ? undefined : navigate,
      );
      if (response && !response.unauthorized) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, verifyToken };
};

export default useVerifyToken;
