import "./Quote.scss";

import React, { useEffect } from "react";
import { FaCog } from "react-icons/fa";

import HeaderButtons from "../../components/Utils/HeaderButtons/HeaderButtons";
import useQuoteStore from "../../stores/QuoteStore";
import QuoteType from "../../types/Quote";
import QuoteForm from "./QuoteForm/QuoteForm";
import QuoteList from "./QuoteList/QuoteList";
import QuoteSettings from "./QuoteSettings/QuoteSettings";

const Quote: React.FC = () => {
  const {
    quoteActiveButton,
    setQuoteActiveButton,
    quoteToEdit,
    setQuoteToEdit,
  } = useQuoteStore();

  const handleEditQuote = (quote: QuoteType) => {
    console.log(quote);
    setQuoteToEdit(quote);
    setQuoteActiveButton("Modifier");
  };

  useEffect(() => {
    if (quoteActiveButton === "Créer" || quoteActiveButton === "Liste") {
      setQuoteToEdit(null);
    }
  }, [quoteActiveButton]);

  const buttons = [
    { id: "Créer", render: "Créer" },
    { id: "Modifier", render: "Modifier" },
    { id: "Liste", render: "Liste" },
    { id: "Settings", render: <FaCog /> },
  ];

  return (
    <div className="root">
      <HeaderButtons
        activeButton={quoteActiveButton}
        setActiveButton={setQuoteActiveButton}
        buttons={buttons}
      />
      <div className="quotePage">
        {quoteActiveButton === "Créer" && <QuoteForm />}
        {quoteActiveButton === "Modifier" &&
          (quoteToEdit ? (
            <QuoteForm />
          ) : (
            <QuoteList onEdit={handleEditQuote} onEditPage={true} />
          ))}

        {quoteActiveButton === "Liste" && (
          <QuoteList onEdit={handleEditQuote} />
        )}
        {quoteActiveButton === "Settings" && <QuoteSettings />}
      </div>
    </div>
  );
};
export default Quote;
