import React from "react";
import { FaPlus, FaTimes } from "react-icons/fa";

import Button from "../../../components/Utils/Button/Button";
import ServiceSelect from "../../../components/Utils/ProductSelect/ServiceSelect";
import { StyledInputNumber } from "../../../components/Utils/StyledInput/StyledInput";
import useUserStore from "../../../stores/UserStore";
import Service, { SelectedService } from "../../../types/Service";
import { calculateTotal } from "./ProductTable";

interface ServiceTableProps {
  selectedServices: SelectedService[];
  setSelectedServices: React.Dispatch<React.SetStateAction<SelectedService[]>>;
  services: Service[];
  addServiceRow: () => void;
}

const ServiceTable: React.FC<ServiceTableProps> = ({
  selectedServices,
  setSelectedServices,
  services,
  addServiceRow,
}) => {
  const { isVatSubject } = useUserStore();

  const handleQuantityChange = (value: string | null, index: number) => {
    const updatedProducts = [...selectedServices];
    const newQuantity =
      value === null ? undefined : Math.max(0, parseInt(value, 10));
    updatedProducts[index].quantity = newQuantity;

    setSelectedServices(updatedProducts);
  };

  const handlePriceChange = (value: string | null, index: number) => {
    const updatedProducts = [...selectedServices];
    updatedProducts[index].price = value || "0";
    setSelectedServices(updatedProducts);
  };

  const handleTvaRateChange = (value: string | null, index: number) => {
    const updatedProducts = [...selectedServices];
    updatedProducts[index].tva_rate = Number(value);
    setSelectedServices(updatedProducts);
  };

  const handleDiscountChange = (value: string | null, index: number) => {
    const updatedProducts = [...selectedServices];
    const discount = Number(value) || 0;
    // Calculer le total HTVA sans réduction
    const price = parseFloat(updatedProducts[index].price || "0");
    const quantity = updatedProducts[index].quantity || 0;
    const totalHTVA = price * quantity;

    if (discount > totalHTVA) return;

    updatedProducts[index].discount = discount > totalHTVA ? 0 : discount;
    setSelectedServices(updatedProducts);
  };

  const calculateSubtotal = (selectedProducts: SelectedService[]) => {
    return selectedProducts
      .reduce((accumulator, currentProduct) => {
        // Assurez-vous que le prix et la quantité ne sont pas undefined ou null, sinon utilisez 0
        const price = parseFloat(currentProduct.price || "0");
        const quantity = currentProduct.quantity || 0;
        const tvaRate = currentProduct.tva_rate || 0;
        const discount = currentProduct.discount || 0;

        const totalHTWithDiscount = price * quantity - discount;
        return accumulator + totalHTWithDiscount * (1 + tvaRate / 100);
      }, 0)
      .toFixed(2); // Formatez le résultat en une chaîne avec deux décimales
  };

  const deleteServicetRow = (rowId: number | undefined) => {
    setSelectedServices((prevServices) =>
      prevServices.filter((service) => service.row_id !== rowId),
    );
  };
  return (
    <div className="tableResponsive">
      <table className="table serviceTable">
        <thead>
          <tr>
            <th>Service</th>
            <th>Quantité</th>
            <th>Prix / unité</th>
            <th>Réduc</th>
            {isVatSubject && <th>TVA ( % ) </th>}
            {isVatSubject && <th>TOTAL HTVA</th>}
            <th>TOTAL TTC</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectedServices.map((service, index) => (
            <tr
              key={
                "service" +
                service.service_id +
                (selectedServices[index].row_id ?? 0)
              }
            >
              <td>
                <ServiceSelect
                  setValue={(newProduct) => {
                    const updatedProducts = [...selectedServices];
                    const index = updatedProducts.findIndex(
                      (prod) => prod.row_id === service.row_id,
                    );
                    updatedProducts[index] = {
                      ...newProduct,
                      quantity: service.quantity,
                      row_id: service.row_id,
                    };
                    setSelectedServices(updatedProducts);
                  }}
                  value={service}
                  serviceList={services}
                  selectedServices={selectedServices}
                  placeholder="Selectionnez un service"
                />
              </td>
              <td>
                <StyledInputNumber
                  label=""
                  value={service.quantity?.toString() || null}
                  setValue={(value) => handleQuantityChange(value, index)}
                  min={"0"}
                  className="quantityInput"
                />
              </td>
              <td>
                <StyledInputNumber
                  label=""
                  value={service.price || null}
                  setValue={(value) => handlePriceChange(value, index)}
                  min={"0"}
                  className="priceInput"
                />
              </td>
              <td>
                <StyledInputNumber
                  label=""
                  value={service.discount ? service.discount.toString() : "0"}
                  setValue={(value) => handleDiscountChange(value, index)}
                  max={(
                    Number(service.price) * Number(service.quantity)
                  ).toString()}
                  className="discountInput"
                />
              </td>
              {isVatSubject && (
                <td>
                  <StyledInputNumber
                    label=""
                    value={service.tva_rate ? service.tva_rate.toString() : "0"}
                    setValue={(value) => handleTvaRateChange(value, index)}
                    className="vatInput"
                  />
                </td>
              )}
              {isVatSubject && (
                <td>
                  {calculateTotal(
                    service.price,
                    service.quantity,
                    service.discount,
                  )}
                </td>
              )}
              <td>
                {calculateTotal(
                  service.price.toString(),
                  service.quantity,
                  service.discount,
                  service.tva_rate,
                )}
              </td>

              <td>
                <Button
                  onClick={() => deleteServicetRow(service.row_id)}
                  icon={<FaTimes color="red" />}
                  className="squareButton"
                  style="square"
                ></Button>
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            {isVatSubject && (
              <>
                <td></td>
                <td></td>
              </>
            )}
            <td>
              <Button
                onClick={() => addServiceRow()}
                icon={<FaPlus />}
                className="squareButton"
                style="square"
              ></Button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>SOUS-TOTAL</td>
            <td></td>
            <td></td>
            <td></td>
            {isVatSubject && (
              <>
                <td></td>
                <td></td>
              </>
            )}
            <td>{calculateSubtotal(selectedServices)} €</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ServiceTable;
