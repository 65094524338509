import "./Category.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";

import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Button from "../../../components/Utils/Button/Button";
import DynamicTable from "../../../components/Utils/DynamicTable/DynamicTable";
import Modal from "../../../components/Utils/Modal/Modal";
import Spinner from "../../../components/Utils/Spinner/Spinner";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import CategoryType from "../../../types/Category";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import ModalContent, { ModalFooterButton } from "./ModalContent/ModalContent";

const Category = () => {
  const [categories, setCategories] = useState<CategoryType[]>();
  const [search, setSearch] = useState("");
  const { loadingStates, setLoading } = useLoadingStore();
  const [updatedCategory, setUpdatedCategory] = useState<CategoryType>({
    category_id: -1,
    name: "",
    description: "",
  });

  const fetchData = async () => {
    setLoading("fetchCategories", true);
    const categoryData = await appFetch(`/api/category/getCategoryList`);
    if (categoryData) {
      console.log(categoryData);
      setCategories(categoryData.categorys);
    }
    setLoading("fetchCategories", false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createCategory = async () => {
    if (!updateCategory.name) {
      toast.error("Veuillez saisir le nom de la catégorie.");
      return;
    }
    setLoading("createCategory", true);
    const response = await appFetch(`/api/category/createCategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...updatedCategory,
      }),
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      fetchData();
      setUpdatedCategory({
        category_id: -1,
        name: "",
        description: "",
      });
    }
    setLoading("createCategory", false);
  };

  const updateCategory = async (category_id: number) => {
    const { name, description } = updatedCategory;
    // Assurez-vous que les données nécessaires sont présentes
    if (!category_id || category_id === -1) {
      toast.error("ID de l'utilisateur non spécifié.");
      return;
    }
    setLoading("createCategory", true);

    const response = await appFetch(`/api/category/updateCategory`, {
      method: "PUT", // Utilisez PUT pour une mise à jour
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        category_id,
        name,
        description,
      }),
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      fetchData();
    }
    setLoading("createCategory", false);
  };

  const filteredCategories = categories?.filter((category) => {
    return (
      category.name.toLowerCase().includes(search.toLowerCase()) ||
      category.description?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const deleteCategory = async (categoryId: number | undefined) => {
    if (!categoryId) return;

    setLoading(`delete${categoryId}`, true);
    const response = await appFetch(
      `/api/category/deleteCategory?category_id=${categoryId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      setCategories(
        categories?.filter((category) => category.category_id !== categoryId),
      );
    }
    setLoading(`delete${categoryId}`, false);
  };

  const collumns = [
    {
      title: "Nom",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, category: CategoryType) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Modal
            modalTitle="Modifier la catégorie"
            modalContent={
              <ModalContent
                setupdatedCategory={setUpdatedCategory}
                updatedCategory={updatedCategory}
              />
            }
            modalButton={({ onClick }) => (
              <Button
                icon={<FiEdit2 />}
                onClick={onClick}
                className="editButton"
                style="square"
              />
            )}
            modalFooterButton={
              <ModalFooterButton
                onClick={() => updateCategory(category.category_id)}
                type="Modifier"
              />
            }
            onClick={() => setUpdatedCategory(category)}
          />

          <DeleteButton
            onClick={() => deleteCategory(category.category_id)}
            isLoading={loadingStates[`delete${category.category_id}`]}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={`categoryListPage`}>
      <div className="pageHeader">
        <StyledInputText
          label="Rechercher"
          value={search}
          setValue={setSearch}
          className="searchInput"
        />
        <Modal
          modalTitle="Créer une catégorie"
          modalContent={
            <ModalContent
              setupdatedCategory={setUpdatedCategory}
              updatedCategory={updatedCategory}
            />
          }
          modalButton={({ onClick }) => (
            <Button label="Créer" onClick={onClick} className="createButton" />
          )}
          modalFooterButton={
            <ModalFooterButton onClick={createCategory} type="Créer" />
          }
          onClick={() => {
            setUpdatedCategory({
              category_id: -1,
              name: "",
              description: "",
            });
          }}
        />
      </div>

      <div className="tableContainer">
        {loadingStates.fetchCategories ? (
          <Spinner />
        ) : (
          <DynamicTable
            data={filteredCategories ?? []}
            columns={collumns}
            onClick={(record) => console.log(record)}
          />
        )}
      </div>
    </div>
  );
};

export default Category;
